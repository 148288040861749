<template>
    <!-- 新建图集 -->
    <!-- 注意:新建图集和添加内容百分之98%的布局一样，有小部分字体颜色不一样不知道是故意的还是写错了。不知道是两页还是一页，
        这里采用了一个页，如果是两页拆分就好，发布审核黄色的class：active -->
    <div id="newAtlas">
        <div class="title pcClass">
            新建图集
            <div class="title-rule" @click="myTipShowBtn">
                <div class="title-rule-img"></div>
                规则说明
            </div>
        </div>
        <div class="card pcClass">
            <div class="card-title">图集名称</div>
            <!--<div class="card-content">-->
                <!--<input type="text" placeholder="图集名称，不能超过32个字符" v-model="title">-->
            <!--</div>-->
            <div class="card-content">
                <el-input
                        type="text"
                        placeholder="图集名称，不能超过32个字符"
                        v-model="title"
                        maxlength="32"
                >
                </el-input>
            </div>
        </div>
        <el-upload
                class="card pcClass upload dragBox"
                drag
                :headers="headersObj"
                action="http://admin.zaihua.art/api/common/upload"
                multiple
                :on-progress="onProgress"
                :on-success="onSuccess"
                :show-file-list="showFileList"
                accept="image/*"
                ref="fileupload"
                :limit="50"
                :on-exceed="limitErrorFun"
        >
            <div class="upload-title">已上传{{myAtlasList.length}}/50张图片</div>
            <div class="upload-button">上传本地文件</div>
            <div class="upload-foot">或拖放到这里</div>
        </el-upload>
        <!--<div class="card pcClass upload">-->
            <!--<div class="upload-title">可上传0/50张图片</div>-->
            <!--<div class="upload-button">上传本地文件</div>-->
            <!--<div class="upload-foot">或拖放到这里</div>-->
        <!--</div>-->
        <div class="card pcClass" v-if="myAtlasList.length > 0">
            <!-- 图集组件 -->
            <myAtlas
                    :page="pageType"
                    :myAtlasList="myAtlasList"
                    :cover="cover"
                    @onCover="onCover"
                    @onDelete="onDelete"
            ></myAtlas>
        </div>
        <div class="card pcClass">
            <div class="card-title">标签</div>
            <div class="card-content">
                <div class="card-content-box">
                    <div class="tagBox" v-for="(item,index) in tagArr" :key="item">
                        <div class="tagContent">{{item}}</div>
                        <div class="tagDelete" @click="tagDeleteBtn(index)">
                            <img src="../img/fork.png"/>
                        </div>
                    </div>
                    <input type="text" placeholder="输入标签按回车分割" @keyup.enter="tagInputEnter" @keyup.delete="tagInputDelete" v-model="tagStr" @input="tagInputEvent">
                </div>
            </div>
        </div>
        <div class="button" :class="!!title && myAtlasList.length > 0 ?'active':''" @click="submitBtn">发布审核</div>

        <myTip :show="myTipShow"
               title="规则说明"
               :text="ruleText"
               @onCloseClick="onCloseClick"
               @onButtonClick="onButtonClick">
        </myTip>
    </div>
</template>

<script>
export default {
    data(){
        return {
            title:'',
            headersObj:{
                token:''
            },
            myAtlasList:[],
            showFileList:false,
            pageType:'add',
            cover:0,

            tagStr:'',
            tagArr:[],
            tagDelete:false,

            myTipShow:false,
            ruleText:'',
        }
    },
    mounted(){
        this.headersObj = {
            token:localStorage.getItem("token")
        };
        this.refreshRuleTextData();
    },
    methods:{
        //上传事件
        onProgress(response, file, fileList){
            this.imageFun(fileList);
        },
        //算则图片并上传成功样式
        onSuccess(response, file, fileList){
            this.imageFun(fileList);
        },
        //图片上传超出限制提示
        limitErrorFun:function(){
            this.$message({
                message: '最多可上传50张图片',
                type: 'warning'
            });
        },
        //设为封面
        onCover:function(index){
            this.cover = index;
        },
        //删除图片
        onDelete:function(index){
            let uploadFiles = this.$refs['fileupload'].uploadFiles;
            let imageObj = this.myAtlasList[index];
            this.refreshDeleteImageData({
                url:imageObj.id
            });
            uploadFiles.splice(index,1);
            this.myAtlasList.splice(index,1);
            if(this.cover >= index){
                this.cover = this.cover - 1;
            }
        },
        //标签输入框输入事件
        tagInputEvent(){
            if(this.tagStr){
                this.tagDelete = false;
            }
        },
        //标签输入框回车事件
        tagInputEnter:function(){
            function unique(arr){
                for(var i=0; i<arr.length; i++){
                    for(var j=i+1; j<arr.length; j++){
                        if(arr[i]==arr[j]){         //第一个等同于第二个，splice方法删除第二个
                            arr.splice(j,1);
                            j--;
                        }
                    }
                }
                return arr;
            }

            let _this = this;
            let tagArr = _this.tagArr;
            tagArr.push(_this.tagStr);
            _this.tagArr = unique(tagArr);
            _this.tagStr = '';
        },
        //标签输入框删除事件
        tagInputDelete(){
            if(this.tagStr === ''){
                if(this.tagDelete){
                    // this.tagStr = this.tagArr.pop() || '';
                    this.tagArr.pop();
                    // this.tagStr = '';
                }else{
                    this.tagDelete = true;
                }
            }
        },
        //标签删除按钮
        tagDeleteBtn:function(index){
            this.tagArr.splice(index,1);
        },
        //发布审核按钮
        submitBtn:function(){
            var _this = this;
            if(!_this.title){
                this.$message({
                    message: '请输入图集名称',
                    type: 'warning'
                });
                return
            }
            if(!_this.myAtlasList.length){
                this.$message({
                    message: '请添加图片',
                    type: 'warning'
                });
                return
            }
            var opt = {
                name:_this.title,
            };
            function unique(arr){
                for(var i=0; i<arr.length; i++){
                    for(var j=i+1; j<arr.length; j++){
                        if(arr[i]==arr[j]){         //第一个等同于第二个，splice方法删除第二个
                            arr.splice(j,1);
                            j--;
                        }
                    }
                }
                return arr;
            }
            var tagArr = _this.tagArr;
            if(_this.tagStr){
                tagArr.push(_this.tagStr);
                _this.tagStr = '';
                tagArr = unique(tagArr);
            }
            opt.tag = tagArr.join(',');
            var imageArr = [];
            for(var i = 0;i<_this.myAtlasList.length;i++){
                imageArr.push(_this.myAtlasList[i].cover);
            }
            opt.pic = imageArr.join(',');
            opt.cover = imageArr[_this.cover];
            opt.token = localStorage.getItem("token");
            opt.web_channel = 'pc';
            _this.refreshSubmitImageData(opt,()=>{
                this.title = '';
                this.$refs['fileupload'].clearFiles();
                this.myAtlasList = [];
                this.tagArr = [];
                this.tagStr = '';
                this.cover = 0;
                this.$message({
                    message: '发布成功',
                    type: 'success'
                });
            });
        },
        //展示规则说明按钮
        myTipShowBtn:function(){
            this.myTipShow = true;
        },
        //规则说明关闭按钮
        onCloseClick:function(){
            this.myTipShow = false;
        },
        //规则说明确定按钮
        onButtonClick:function(){
            this.myTipShow = false;
        },

        //刷新规则说明
        refreshRuleTextData:function(){
            this.$http.get("/api/setting/get_config_info",{}).then((res)=>{
                this.ruleText = res.data.tjgz;
            })
        },
        //发布图集
        refreshSubmitImageData:function(options,fun){
            this.$http.get("/api/painting/add_painting",{params:options}).then((res)=>{
                console.log(res);
                if(fun){
                    fun();
                }
            })
        },
        //删除图片
        refreshDeleteImageData:function(options,fun){
            this.$http.get("/api/common/deleteUpload",{params:options}).then((res)=>{
                console.log(res);
                if(fun){
                    fun();
                }
            })
        },

        //封装方法
        imageFun(fileList){
            var arr_ = JSON.parse(JSON.stringify(this.myAtlasList));
            this.myAtlasList = [];
            var url = '';
            for(let i=0;i<fileList.length;i++){
                url = URL.createObjectURL(fileList[i].raw);
                for(var j = 0;j<arr_.length;j++){
                    if(arr_[j].name === fileList[i].name){
                        if(arr_[j].coverUrl){
                            url = arr_[j].coverUrl;
                        }
                    }
                }
                if(fileList[i].response && fileList[i].response.data && fileList[i].response.data.url){
                    url = fileList[i].response && fileList[i].response.data && fileList[i].response.data.url;
                }
                this.myAtlasList.push({
                    cover:url || '',
                    coverUrl:url || '',
                    is_buy:true,
                    id:url || '',
                    name:fileList[i].name,
                    percentage:fileList[i].percentage,
                    status:fileList[i].status,
                })
            }
            var arr = this.myAtlasList;
            arr.sort((a,b)=>{
                if(a.name < b.name){
                    return -1;
                }else if(a.name > b.name){
                    return 1;
                }else{
                    return 0;
                }
            });
            this.myAtlasList = arr;
        },
    }
}
</script>

<style>

</style>