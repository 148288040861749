<template>
  <div id="qqLogin"></div>
</template>

<script>
export default {
  name: "qqLogin",
  mounted() {


    let code = '';
    let href = location.href;
    let dataStr = href.split('?')[1];
    if(dataStr){
      let arr = dataStr.split('&');
      for(let i = 0;i<arr.length;i++){
        if('code' === arr[i].split('=')[0]){
          code = arr[i].split('=')[1];
        }
      }
    }
    // console.log(code);
    let loginUrl = localStorage.getItem('qqLoginUrl');
    // console.log(loginUrl + '?code=' + code + '&type=qqLogin');
    window.location.href = loginUrl + '?code=' + code + '&type=qqLogin';
    // this.$http.get("/api/index/qqpclogin",{
    //   params:{
    //     code:code
    //   }
    // }).then((res)=>{
    //   console.log(res)
    // });
  }
}
</script>

<style scoped>

</style>