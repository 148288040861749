<template>
    <!-- 全部分类-图集类型 -->
    <!-- 注意：人物、景物...的筛选没有黄色图 -->
    <div id="allCategories">
        <div class="section">
            <div class="section-banner pcClass">
                <div class="section-banner-top">
                    <div class="banner-top-list" v-for="(item,index) in dataList" :key="index"
                        :class="listCurrent===index?'active':''"
                        @click="dataListClick(index)">
                        <!--<img :src="$uploadUrl+item.image" alt="" class="top-list-img">-->
                        <img :src="require('../img/'+imgList[index]+'.svg')" alt="" class="top-list-img">
                        <img :src="require('../img/'+imgList_active[index]+'.svg')" alt="" class="top-list-img active">
                        <!--<img src='../img/top1.png' alt="" :class="'top-list-img ' + imgList[index]">-->
                        <div class="top-list-text">{{item.name}}</div>
                        <div class="top-list-circle" v-if="item.is_red"></div>
                    </div>
                </div>
            </div>
            <div class="section-physicalStructure pcClass"  v-show="versionList.length">
                <div class="section-physicalStructure-list" 
                    v-for="(item,index) in versionList" :key="index"
                    :class="versionCurrent==index?'active':''"
                    @click="physicalStructureClick(index)">
                    {{item.name}}
                    <div class="physicalStructure-list-circle" v-if="item.is_red"></div>
                </div>
            </div>
            <div class="section-tip pcClass">
                <div>共{{myAtlasList.length}}个图集</div>
                <div class="section-tip-select">
                    {{moduleSelectText}}类型
                    <div class="section-tip-img"></div>
                    <div class="section-tip-module">
                        <div class="module-select" 
                            v-for="(item,index) in moduleSelect" :key="index"
                            :class="moduleCurrent===index?'active':''"
                            @click="moduleSelectClick(index)">
                            {{item.text}}
                            <div class="module-select-check" v-if="moduleCurrent===index"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 图集组件 -->
        <myAtlas page="index" v-loading="fullscreenLoading" :myAtlasList="myAtlasList"></myAtlas>
        <!-- 底部组件 -->
        <myFooter></myFooter>
    </div>
</template>

<script>
export default {
    data(){
        return{
            dataList:[
                {
                    className:"character",
                    text:"人物"
                },
                {
                    className:"scenery",
                    text:"景物"
                },
                {
                    className:"stillLife",
                    text:"静物"
                },
                {
                    className:"animal",
                    text:"动物"
                },
                {
                    className:"quadraticElement",
                    text:"二次元"
                }
            ],
            listCurrent:0,
            versionList:[
                {text:"人体结构"},
                {text:"头部肖像"},
                {text:"动势造型"},
                {text:"职业服饰"}
            ],
            versionCurrent:0,
            moduleSelect:[
                {text:"全部"},
                {text:"免费"},
                {text:"精选"}
            ],
            moduleCurrent:0,
            moduleSelectText:"全部",
            myAtlasList:[],     //图集列表
            oId:null,
            fullscreenLoading:false,
            imgList:[
                'web_fl_renwu_black','web_fl_fengjing_black','web_fl_jingwu_black','web_fl_dongwu_black','web_fl_erciyuan_black',
            ],//图标
            imgList_active:[
                'web_fl_renwu_yellow','web_fl_fengjing_yellow','web_fl_jingwu_yellow','web_fl_dongwu_yellow','web_fl_erciyuan_yellow',
            ],//图标
        }
    },
    mounted(){
        this.listCurrent=Number(this.$route.params.index);
        let pid = Number(this.$route.params.pid);
        let id = Number(this.$route.params.id);
        this.getCategory(()=>{
            if(pid && id){
                for(let i = 0;i<this.dataList.length;i++){
                    if(this.dataList[i].id === pid){
                        this.versionList = this.dataList[i].childlist;
                        this.listCurrent = i;
                        console.log(this.versionList);
                        console.log(this.listCurrent);
                        for(let j = 0;j<this.versionList.length;j++){
                            if(this.versionList[j].id === id){
                                this.versionCurrent = j;
                                this.oId=this.versionList[this.versionCurrent].id;
                            }
                        }
                    }
                }
            }
        })
    },
    methods:{
        // 类型切换
        dataListClick(index){
            this.listCurrent=index;
            this.versionList=this.dataList[index].childlist;
            this.versionCurrent = 0;
            if(this.versionList.length){
                this.oId=this.versionList[this.versionCurrent].id;
                this.moduleSelectClick(this.moduleCurrent)
            }else{
                this.oId=this.dataList[this.listCurrent].id;
                this.moduleSelectClick(this.moduleCurrent)
            }
        },
        // 最新/推荐图集切换
        physicalStructureClick(index){
            this.versionCurrent=index;
            this.oId=this.versionList[index].id;
            this.moduleSelectClick(this.moduleCurrent)
        },
        // 图集筛选
        moduleSelectClick(index){
            this.moduleCurrent=index;
            //console.log(this.moduleSelect[index].text)
            switch(this.moduleSelect[index].text){
                case "全部":
                    this.getAtlasData();
                    this.moduleSelectText="全部";
                    break;
                case "免费":
                    this.getFreeAtlasData();
                    this.moduleSelectText="免费";
                    break;
                case "精选":
                    this.getSelectedAtlasData();
                    this.moduleSelectText="精选";
                    break;
            }
        },
        // 获得分类
        getCategory(fun){
            this.$http.get("/api/painting/get_category",{
            }).then((res)=>{
                this.dataList=res.data;
                this.versionList=this.dataList[this.listCurrent].childlist;
                if(this.versionList.length){
                    this.oId=this.versionList[0].id;
                    if(fun){
                        fun();
                    }
                    this.getAtlasData()
                }else{
                    this.oId=this.dataList[this.listCurrent].id;
                    if(fun){
                        fun();
                    }
                    this.getAtlasData()
                }
            })
        },
        // 查看全部图集
        getAtlasData(){
            this.fullscreenLoading=true
            this.$http.get("/api/painting/get_painting",{
                params:{
                    category_id:this.oId,
                    sort:'desc'
                }
            }).then((res)=>{
                this.fullscreenLoading=false;
                this.myAtlasList=res.data;
            })
        },
        // 查看免费图集
        getFreeAtlasData(){
            this.fullscreenLoading=true;
            this.$http.get("/api/painting/get_painting",{
                params:{
                    category_id:this.oId,
                    channel:'admin',
                    is_vip:false,
                    sort:'desc'
                }
            }).then((res)=>{
                this.fullscreenLoading=false;
                this.myAtlasList=res.data
            })
        },
        // 查看精选图集
        getSelectedAtlasData(){
            this.fullscreenLoading=true;
            this.$http.get("/api/painting/get_painting",{
                params:{
                    category_id:this.oId,
                    channel:'user',
                    is_jp:true,
                    sort:'desc'
                }
            }).then((res)=>{
                this.fullscreenLoading=false
                this.myAtlasList=res.data;
            })
        }
    }
}
</script>

<style>

</style>