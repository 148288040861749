<template>
    <div class="myFooter">
        <div class="pcClass content">
            <div class="content-left">
                <ul v-for="(item,index) in list" :key="index">
                    <li v-for="(value,current) in item.ul" :key="current" 
                        @click="footerClick(value.text,current)">
                        {{value.text}}
                    </li>
                </ul>
            </div>
            <div class="content-right">
                <!--<div class="content-right-Qrcode">-->
                    <!--&lt;!&ndash;<img src="../img/deleteQrcode.jpg" alt="">&ndash;&gt;-->
                    <!--<div class="qrcode">-->
                        <!--<img src="../img/deleteQrcode.jpg" alt="">-->
                    <!--</div>-->
                    <!--在画小程序-->
                <!--</div>-->
                <div class="content-right-Qrcode">
                    <!--<img src="../img/deleteQrcode.jpg" alt="">-->
                    <div class="qrcode" ref="qrCodeUrl"></div>
                    在画手机客户端
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2'
export default {
    name:"myFooter",
    data(){
        return{
            list:[
                {
                    ul:[
                        {text:"关于我们"},
                        {text:"服务使用协议"},
                        {text:"隐私协议"}
                    ]
                },
                {
                   ul:[
                        {text:"更新日志"},
                        {text:"意见反馈"}
                    ] 
                }
            ]
        }
    },
    mounted() {
        this.creatQrCode();
    },
    methods:{
        footerClick(text,current){
            console.log(text,current)
            switch(text){
                case "关于我们":this.$router.push({path:`/concern/`});break;
                case "服务使用协议":this.$router.push({path:`/concern/serviceUsageAgreement`});break;
                case "隐私协议":this.$router.push({path:`/concern/privacyAgreement`});break;
                case "更新日志":this.$router.push({path:`/concern/updatelog`});break;
                case "意见反馈":this.$router.push({path:`/concern/feedback`});break;
            }
            // this.$router.push({path:`/concern`})
        },
        creatQrCode() {
            // console.log(QRCode);
            new QRCode(this.$refs.qrCodeUrl, {
                text: 'http://h5.zaihua.art/#/pages/download/download', // 需要转换为二维码的内容
                width: 100,
                height: 100,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },
    }
}
</script>

<style>

</style>