<template>
    <!-- 充值组件 -->
    <!-- 注意：1.充值金额无法取字体大小，现有的字体大小是自己瞎写的
              2.自定义设置金额后金瓜子会随着层长的布局不知道，目前只采用了页面还原
              3.选择钱的时候，有个选中图片好像不太规范，详见选择钱的状态 -->
    <div class="mask payment" v-show="paymentShow">
        <div class="mask-section">
            <div class="close" @click="closeClick"></div>
            <div class="head">
                <div class="head-balance">账户余额：{{userInfo.money*1}}金瓜子</div>
                <div class="head-title">{{title}}</div>
                <div class="head-history" @click="orderPage">充值记录</div>
            </div>
            <div class="content">
                <div class="content-title">充值金额</div>
                <div class="content-money">
                    <div class="money-list" v-for="(item,index) in money" :key="index"
                         @click="selectMoneyClick(index)"
                         :class="index==current?'active':''">
                        <div class="money-list-jinguazi">
                            <div class="money-list-img"></div>
                            {{item.count*1}}
                        </div>
                        <div class="money-list-money">￥{{item.price*1}}</div>
                    </div>
                    <div class="money-list money-custom"
                         @click="selectMoneyClick(-2)"
                         :class="current==-2?'active':''">
                        <div class="money-custom-tip">
                            <input type="text" placeholder="输入其他金额" :value="moneyInput.value" @input="inputChangeEvent">
                        </div>
                        <!--<input type="number" placeholder="输入其他金额" v-model="moneyInput.value" @input="inputChangeEvent">-->
                        <div class="money-list-jinguazi">
                            <div class="money-list-img"></div>
                            <div>{{jgz || 0}}</div>
                        </div>
                    </div>
                </div>
                <div class="content-title active">支付方式</div>
                <div class="content-pay">
                    <div class="content-pay-type">
                        <div class="pay-type-list"
                             v-for="(item,index) in payType" :key="index"
                             @click="payTypeClick(index)"
                             :class="payTypeCurrent==index?'active':''">
                            <div class="type-list-img" :class="item.className"></div>
                            {{item.type}}支付
                        </div>
                    </div>
                    <div class="content-pay-qrCode">
                        <!--<img src="" alt="">-->
                        <div class="qrCodeBox" ref="qrCodeUrl" v-show="payTypeCurrent === 0"></div>
                        <div class="qrCodeBox" v-show="payTypeCurrent === 1">
                            <iframe :src="qrCodeUrl" scrolling="no" frameborder="no"></iframe>
                        </div>
                        <div class="text">请使用{{payType[payTypeCurrent].type}}扫码，支付<span>{{orderInfo.price || 0}}</span>元</div>
                    </div>
                </div>
                <div class="content-tip"><span @click="tipsClick">阅读并同意《充值服务协议》</span><span class="question" @click.stop="questionBtn">充值遇到问题？</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2'

    export default {
        name: "payment",
        props:{
            title:{
                type:String,
                default:"金瓜子数量不足"
            }
        },
        watch:{
            // paymentShow(newVal,oldVal){
            paymentShow(newVal){
                if(newVal === true || newVal === 'true'){
                    if(this.money.length > 0){
                        this.selectMoneyClick(0);
                    }else{
                        this.refreshMoneyListData(()=>{
                            this.selectMoneyClick(0);
                        });
                    }
                }
            }
        },
        data() {
            return {
                userInfo: {},
                orderInfo: {},
                payInfo: {},

                paymentShow: false,
                money: [
                    // {
                    //     price:1880,
                    //     conut:188
                    // },
                ],
                // current: -1,
                current: 0,
                payType: [
                    {
                        className: "weChat",
                        type: "微信",
                        key: "wechat"
                    },
                    // {
                    //     className: "alipay",
                    //     type: "支付宝",
                    //     key: "alipay"
                    // }
                ],
                payTypeCurrent: 0,

                moneyInput: {value: ''},
                inputTimeoutObj: null,
                jgz: '',

                qrCodeObj: null,
                qrCodeUrl:'',

                refreshOrderTimeout: null,

                myTipShow:false,
            }
        },
        mounted() {
            this.refreshUserInfoData();
            this.refreshMoneyListData();
        },
        methods: {
            //选择金额
            selectMoneyClick(index) {
                var _this = this;
                _this.current = index;
                if(index === '-2' || index === -2){
                    if (_this.moneyInput.value && _this.moneyInput.value > 0) {
                        _this.$refs.qrCodeUrl.innerHTML = '';
                        _this.qrCodeUrl = '';
                        _this.refreshMoneyToJGZData(function () {
                            _this.refreshAddOrderData(function () {
                                _this.refreshPaymentData(function(){
                                    _this.refreshQRCode();
                                });
                            });
                        });
                    } else {
                        _this.jgz = 0;
                        _this.$refs.qrCodeUrl.innerHTML = '';
                        _this.qrCodeUrl = '';
                        if(_this.refreshOrderTimeout){
                            clearTimeout(_this.refreshOrderTimeout);
                        }
                    }
                }else{
                    _this.$refs.qrCodeUrl.innerHTML = '';
                    _this.qrCodeUrl = '';
                    _this.refreshAddOrderData(function () {
                        _this.refreshPaymentData(function(){
                            _this.refreshQRCode();
                        });
                    });
                }
            },
            //输入金额
            inputChangeEvent(e) {
                var _this = this;
                var value = e.target.value || '';
                _this.moneyInput = {value: value.replace(/[^\d]/g, '')};
                if (_this.inputTimeoutObj) {
                    clearTimeout(_this.inputTimeoutObj);
                }
                _this.inputTimeoutObj = setTimeout(function () {
                    if (_this.moneyInput.value && _this.moneyInput.value > 0) {
                        _this.$refs.qrCodeUrl.innerHTML = '';
                        _this.qrCodeUrl = '';
                        _this.refreshMoneyToJGZData(function () {
                            _this.refreshAddOrderData(function () {
                                _this.refreshPaymentData(function(){
                                    _this.refreshQRCode();
                                });
                            });
                        });
                    } else {
                        _this.jgz = 0;
                        _this.$refs.qrCodeUrl.innerHTML = '';
                        _this.qrCodeUrl = '';
                        if(_this.refreshOrderTimeout){
                            clearTimeout(_this.refreshOrderTimeout);
                        }
                    }
                }, 100);
            },
            // 支付方式
            payTypeClick(index) {
                var _this = this;
                _this.payTypeCurrent = index;
                _this.$refs.qrCodeUrl.innerHTML = '';
                _this.qrCodeUrl = '';
                _this.refreshPaymentData(function(){
                    _this.refreshQRCode();
                });
            },
            //关闭充值页
            closeClick() {
                var _this = this;

                _this.paymentShow = false;

                if(_this.refreshOrderTimeout){
                    clearTimeout(_this.refreshOrderTimeout);
                }
            },
            // 充值记录
            orderPage() {
                this.paymentShow = false;
                this.$router.push({path: "/order/0"})
            },
            //协议按钮
            tipsClick(){
                this.$emit('tipBtnClick');
            },
            //问题按钮
            questionBtn(){
                window["dysfunctionShowFun"]();
                // this.paymentShow = false;
                // this.$router.push({path:`/concern`,query:{type:'pay'}});
                // this.$router.push({path:`/concern/feedback`});
                // this.$router.push({name:`feedback`,params:{type:'pay'}});
            },

            //获得充值金额
            refreshMoneyListData(fun) {
                this.$http.get("/api/order/get_price", {
                    params: {}
                }).then((res) => {
                    this.money = res.data;
                    // this.current = this.money.length - 1;
                    if(fun){fun();}
                })
            },
            // 刷新个人信息
            refreshUserInfoData(fun) {
                this.$http.get("/api/user/getUser", {
                    params: {}
                }).then((res) => {
                    this.userInfo = res && res.data || {};
                    if(fun){
                        fun();
                    }
                })
            },
            // 刷新手动输入金瓜数
            refreshMoneyToJGZData(fun) {
                var _this = this;
                this.$http.get("/api/order/get_normal_price", {
                    params: {
                        amount: Math.round(_this.moneyInput.value * 100)
                    }
                }).then((res) => {
                    _this.jgz = res.data;
                    if (fun) {
                        fun();
                    }
                })
            },
            //创建订单
            refreshAddOrderData(fun) {
                var _this = this;
                var opt = {
                    user_id: _this.userInfo.id,
                    web_channel: 'pc'
                };
                if (_this.current === -2 || _this.current === '-2') {
                    opt.amount = _this.moneyInput.value;
                } else {
                    opt.id = _this.money[_this.current].id;
                }
                this.$http.post("/api/order/add_g_order", opt).then((res) => {
                    _this.orderInfo = res.data;

                    if(_this.refreshOrderTimeout){
                        clearTimeout(_this.refreshOrderTimeout);
                    }
                    var goFun = function(){
                        _this.refreshOrderTimeout = setTimeout(function(){
                            if(_this.paymentShow){
                                _this.refreshOrderInfoData(function(data){
                                    if(data.status === 'paid'){
                                        _this.refreshUserInfoData(function(){
                                            _this.$message({
                                                message: '充值成功',
                                                type: 'warning'
                                            });
                                            _this.$emit('onCompleteFun');
                                        });
                                    }else{
                                        goFun();
                                    }
                                });
                            }else{
                                goFun();
                            }
                        },1000);
                    };
                    goFun();
                    if (fun) {
                        fun();
                    }
                })
            },
            //支付
            refreshPaymentData(fun) {
                var _this = this;
                this.$http.post("/api/order/pay", {
                    id: _this.orderInfo.id,
                    type: _this.payType[_this.payTypeCurrent].key,
                    method: _this.payType[_this.payTypeCurrent].key === 'alipay'?'web':'scan'
                }).then((res) => {
                    _this.payInfo = res.data;
                    if (fun) {
                        fun();
                    }
                })
            },
            //刷新订单信息判断支付情况
            refreshOrderInfoData(fun){
                var _this = this;
                _this.$http.get("/api/order/get_order_id", {
                    params: {
                        id:_this.orderInfo.id
                    }
                }).then((res) => {
                    if (fun) {
                        fun(res.data);
                    }
                });
            },

            //创建订单刷新二维码
            refreshQRCode() {
                var _this = this;
                if(_this.payTypeCurrent === 0){
                    _this.$refs.qrCodeUrl.innerHTML = '';
                    _this.qrCodeObj = new QRCode(this.$refs.qrCodeUrl, {
                        text: _this.payInfo.code_url, // 需要转换为二维码的内容
                        width: 110,
                        height: 110,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                }else{
                    _this.qrCodeUrl = _this.payInfo;
                }
            },
        }
    }
</script>

<style>

</style>