<template>
    <!-- 推荐图集-查看全部 -->
    <div id="recommendedAtlas">
        <div class="section pcClass">
            <div class="section-title">推荐图集</div>
        </div>
        <!-- 图集组件 -->
        <myAtlas page="index" :myAtlasList="myAtlasList"></myAtlas>
    </div>
</template>

<script>
export default {
    data(){
        return {
            myAtlasList:[],
        }
    },
    mounted(){
        this.refreshRecommendListData();
    },
    methods:{
        //刷新推荐图集
        refreshRecommendListData(){
            this.$http.get("/api/painting/get_recommend_painting",{
                params:{
                    count:100
                }
            }).then((res)=>{
                this.myAtlasList=res.data;
            });
        },
    }
}
</script>

<style>

</style>