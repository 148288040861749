<template>
   <!-- 设置_关于我们_服务使用协议-版本1 -->
    <div id="serviceUsageAgreement">
        <div class="section">
            <div v-html="content"></div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            content:'',
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            // this.$http.get("/api/setting/get_config_info",{
            this.$http.get("/api/index/get_new_agreement",{
            }).then((res)=>{
                // this.content=res.data.fwxy;
                this.content=res.data.fw.content;
            })
        }
    }
}
</script>

<style>

</style>