<template>
    <!-- 搜索图集 -->
    <div id="searchAtlas" v-loading="loading">
        <myAtlas page="index" :myAtlasList="atlasData"></myAtlas>
        <div class="section-user-tip pcClass" v-if="atlasData.length===0">
            — 抱歉,没有找到相关内容 —
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            atlasData:[],
            loading:false
        }
    },
    watch:{
        "$store.state.keyWords"(newval) {
            console.log(newval);
            this.getComprehensiveSearchData()
        },
    },
    mounted(){
        this.getAtlasData()
    },
    methods:{
        // 获取图集和用户
        getAtlasData(){
            this.loading=true;
            this.$http.get("/api/painting/common_search",{
                params:{
                    keywords:this.$store.state.keyWords
                }
            }).then((res)=>{
                this.loading=false;
                this.atlasData=res.data.painting;
            })
        },
    }
}
</script>

<style>

</style>