<template>
    <!-- 图集-推荐图集 -->
    <!-- 注意：动物没有白色图 -->
    <div id="index" ref="indexBox">
        <!-- 轮播图 -->
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in banner" :key="index">
                    <!--<img :src="$uploadUrl+item.pic_pc" alt="" width="100%">-->
                    <el-image :src="$uploadUrl+item.pic_pc" fit="cover" style="width: 100%;height: 100%;"></el-image>
                </div>
            </div>
        </div>
        <!-- 主内容条件筛选 -->
        <!-- v-loading="fullscreenLoading" -->
        <div class="section" v-loading="fullscreenLoading">
            <div class="section-banner pcClass">
                <div class="section-banner-top">
                    <div class="top-list" v-for="(item,index) in dataList" :key="index"
                         @click="dataListClick(index)">
                        <!-- <div class="top-list-img" :class="item.className"></div> -->
                        <!--<img :src="$uploadUrl+item.image" alt="" class="top-list-img">-->
                        <img :src="require('../img/'+imgList[index]+'.svg')" alt="" class="top-list-img">
                        <!--<div class="top-list-img"></div>-->
                        <img :src="require('../img/'+imgList_active[index]+'.svg')" alt="" class="top-list-img active">
                        <div class="top-list-text">{{item.name}}</div>
                    </div>
                </div>
                <div class="section-banner-bottom">
                    <div class="bottom-select" v-for="(item,index) in selectTitile" :key="index"
                         :class="current===index?'active':''"
                         @click="selectClick(index)">
                        {{item.title}}
                    </div>
                </div>
            </div>
        </div>
        <!-- 推荐图集组件 -->
        <myAtlas page="index" :myAtlasList="recommendAtlasList" v-if="current===0"></myAtlas>
        <!-- 最新图集组件 -->
        <myAtlas page="index" :myAtlasList="newestAtlasList" v-if="current===1"></myAtlas>
        <!-- 底部组件 -->
        <myFooter></myFooter>
    </div>
</template>

<script>
    import Swiper from 'swiper';                                  //轮播图组件
    import 'swiper/dist/css/swiper.min.css';                      //轮播图样式
    export default {
        data() {
            return {
                banner: [],
                dataList: [
                    // {
                    //     className:"character",
                    //     text:"人物"
                    // },
                    // {
                    //     className:"scenery",
                    //     text:"景物"
                    // },
                    // {
                    //     className:"stillLife",
                    //     text:"静物"
                    // },
                    // {
                    //     className:"animal",
                    //     text:"动物"
                    // },
                    // {
                    //     className:"quadraticElement",
                    //     text:"二次元"
                    // }
                ],
                imgList:[
                    'web_fl_renwu_black','web_fl_fengjing_black','web_fl_jingwu_black','web_fl_dongwu_black','web_fl_erciyuan_black',
                ],//图标
                imgList_active:[
                    'web_fl_renwu_white','web_fl_fengjing_white','web_fl_jingwu_white','web_fl_dongwu_white','web_fl_erciyuan_white',
                ],//图标
                selectTitile: [
                    {title: "推荐图集"},
                    {title: "最新图集"}
                ],
                current: 0,
                recommendAtlasList: [],
                newestAtlasList: [],
                fullscreenLoading: false,

                listRows1: 100,//推荐图集一页个数
                page2: 1, // 当前页码
                listRows2: 100,


                ulH: 0,//滚动内容实际的高度
                ulHsj: 0,//滚动内容实际的高度
                space: 0,//差值
            }
        },
        mounted() {
            let _this = this;
            _this.getData();
            new Swiper('.swiper-container', {
                autoplay: 1000*3,
                speed: 300,
                loop: false,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: false, //修改swiper的父元素时，自动初始化swiper
                onClick: function (swiper) {
                    // alert('你点了Swiper');
                    // alert(mySwiper.realIndex);     //当前索引
                    var index = swiper.realIndex;
                    var data = _this.banner[index];
                    if(data.type === 'paint'){
                        _this.$router.push({path:`/unlockAtlas/${data.content}`});
                    }
                    if(data.type === 'text'){
                        _this.$router.push({path: `/bannerInfo/${data.id}`});
                    }
                }
            });
            window.addEventListener('scroll', this.scrollEvent);
        },
        methods: {
            /**
             * 初始化赋值
             * */
            initScroll() {
                this.ulH = window.innerHeight;//滚动条盒子的高度
                //console.log(this.$refs);
                //console.log(this.$refs.indexBox);
                //console.log(this.$refs.indexBox.clientHeight);
                this.ulHsj = this.$refs.indexBox.clientHeight + 54;//滚动内容实际的高度
                this.space = this.ulHsj - this.ulH;//差值
            },
            /**
             * 滚动条滚动到底部触发
             * */
            scrollEvent() {
                let _this = this;
                let scrollHeight = window.scrollY;//在盒子内部滚动高度
                if (scrollHeight >= _this.space) {
                    if (this.current === 0 || this.current === '0') {
                        this.getRecommendedAtlas(true);
                    } else if (this.current === 1 || this.current === '1') {
                        this.getNewestAtlas(true);
                    }
                }
            },
            // 类型点击
            dataListClick(index) {
                this.$router.push({path: `/allCategories/${index}/0/0`});
            },
            // 最新/推荐图集切换
            selectClick(index) {
                this.current = index;
                if (this.current === 0 || this.current === '0') {
                    this.getRecommendedAtlas();
                } else if (this.current === 1 || this.current === '1') {
                    this.getNewestAtlas();
                }
            },
            // 获得轮播图
            getBanner() {
                return new Promise((resolve) => {
                    this.$http.get("/api/index/get_banner", {
                        params: {
                            pc: ''
                        }
                    }).then((res) => {
                        resolve(res);
                        this.banner = res.data;
                    })
                })
            },
            // 获得分类
            getCategory() {
                return new Promise((resolve) => {
                    this.$http.get("/api/painting/get_category", {}).then((res) => {
                        resolve(res);
                        this.dataList = res.data
                    })
                })
            },
            // 获取推荐图集
            getRecommendedAtlas(isPage) {
                let _this = this;
                let idArr = [];
                if (isPage) {
                    for (let i = 0; i < _this.recommendAtlasList.length; i++) {
                        idArr.push(_this.recommendAtlasList[i].id);
                    }
                }
                let options = {};
                options.count = _this.listRows1;
                if (idArr.length > 0) {
                    options.not_in = idArr.join(',')
                }
                return new Promise((resolve) => {
                    _this.$http.post("/api/painting/get_recommend_painting", options).then((res) => {
                        // console.log('isPage',isPage);
                        if (isPage) {
                            _this.recommendAtlasList = this.recommendAtlasList.concat(res.data)
                        } else {
                            _this.recommendAtlasList = res.data;
                        }
                        _this.$nextTick(() => {
                            _this.initScroll();//调用下初始化方法
                        });
                        resolve(res);
                    })
                })
            },
            // 获取最新图集
            getNewestAtlas(isPage) {
                let _this = this;
                if (isPage) {
                    _this.page2++;
                } else {
                    _this.page2 = 1;
                }
                return new Promise((resolve) => {
                    this.$http.get("/api/painting/get_painting", {
                        params: {
                            order: 'createtime',
                            sort: 'desc',
                            listRows: _this.listRows2,
                            page: _this.page2
                        }
                    }).then((res) => {
                        if (isPage) {
                            _this.newestAtlasList = this.newestAtlasList.concat(res.data)
                        } else {
                            _this.newestAtlasList = res.data;
                        }
                        _this.$nextTick(() => {
                            _this.initScroll();//调用下初始化方法
                        });
                        resolve(res);
                    })
                })
            },
            // 获取数据
            async getData() {
                this.fullscreenLoading = true;
                await this.getBanner();
                await this.getCategory();
                await this.getRecommendedAtlas();
                await this.getNewestAtlas();
                this.initScroll();
                this.fullscreenLoading = false;
            },
        }
    }
</script>

<style>
    .el-loading-mask {
        z-index: 999 !important;
    }
</style>