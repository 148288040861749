<template>
    <!-- 我的-编辑资料-编辑资料展示 -->
    <div id="editorialPresentation">
        <div class="edit">
            <div class="edit-word">编辑资料</div>
            <div class="edit-head">
                <div class="edit-head-camera"></div>
            </div>
            <p class=edit-click>点击更换头像</p>
            <div class="content">
                <div class="name">
                    <div class="name-left">名字</div>
                    <div class="name-right">
                        <div class="input">
                            <input type="text" class="nameInput" placeholder="达芬奇">
                        </div>
                        <div class="button">
                            <div class="cancel">取消</div>
                            <div class="preservation">保存</div>
                        </div>
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">性别</div>
                    <div class="name-right">
                        <div class="input">
                            <div class="input-sex" @click="selectSex" :class="sex==false?'active':''">
                                <div class="input-sex-man"><div class="circle"></div></div>
                                男
                            </div>
                            <div class="input-sex" @click="selectSex" :class="sex==true?'active':''">
                                <div class="input-sex-woman"><div class="circle"></div></div>
                                女
                            </div>
                        </div>
                        <div class="button">
                            <div class="cancel">取消</div>
                            <div class="preservation">保存</div>
                        </div>
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">微信号</div>
                    <div class="name-right">
                        <div class="input">
                            <input type="text" class="nameInput" placeholder="F80308077">
                        </div>
                        <div class="button">
                            <div class="cancel">取消</div>
                            <div class="preservation">保存</div>
                        </div>
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">生日</div>
                    <div class="name-right">
                        <div class="input">
                            <dir class="input-date">
                                <input type="text" class="nameInput year" placeholder="1999"><span>年</span>
                                <input type="text" class="nameInput month" placeholder="12"><span>月</span>
                                <input type="text" class="nameInput day" placeholder="22"><span>日</span>
                            </dir>
                        </div>
                        <div class="button">
                            <div class="cancel">取消</div>
                            <div class="preservation">保存</div>
                        </div>
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">地区</div>
                    <div class="name-right">
                        <div class="input">
                            <input type="text" class="nameInput" placeholder="北京-大兴">
                        </div>
                        <div class="button">
                            <div class="cancel">取消</div>
                            <div class="preservation">保存</div>
                        </div>
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">简介</div>
                    <div class="name-right">
                        <div class="content-describe">
                            <textarea placeholder="请描述您遇到的问题，我们会尽快解决" 
                                v-model="describe"
                                 maxlength="100">
                            </textarea>
                            <div class="content-describe-font">{{describe.length}}/100</div>
                        </div>
                        <div class="button">
                            <div class="cancel">取消</div>
                            <div class="preservation">保存</div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- <loginComponent :show="true"></loginComponent> -->
        <!--<dysfunction :show="true"></dysfunction>-->
    </div>
</template>

<script>
export default {
    data(){
        return{
            sex:false,
            describe:""
        }
    },
    methods:{
        selectSex(){
            this.sex=!this.sex
        }
    }
}
</script>

<style>

</style>