<template>
  <!-- 设置_关于我们_更新日志-版本1 -->
    <div id="updatelog">
        <div class="content">
            <div class="name" v-for="(item,index) in dataList" :key="index">
                <div class="name-left">{{item.title}}</div>
                <div class="name-right">
                    <span class="test">{{item.time}}</span>
                </div>
            </div>
            <!-- <div class="name">
                <div class="name-left">在画1.0.1版本主要新功能介绍</div>
                <div class="name-right">
                    <span class="test">20201105 10:51</span>
                </div>
            </div>
            <div class="name">
                <div class="name-left">在画1.0.1版本主要新功能介绍</div>
                <div class="name-right">
                    <span class="test">20201105 10:51</span>
                </div>
            </div>
            <div class="name">
                <div class="name-left">在画1.0.1版本主要新功能介绍</div>
                <div class="name-right">
                    <span class="test">20201105 10:51</span>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            dataList:[]
        }
    },
    mounted(){
        this.getUpdateLogData()
    },
    methods:{
        // 获取更新日志
        getUpdateLogData(){
            this.$http.get("/api/setting/get_update_info",{
            }).then((res)=>{
                this.dataList=res.data
            })
        }
    }
}
</script>

<style>

</style>