<template>
    <!-- 头部组件 -->
    <div class="header">
        <div class="contentBox">
            <div class="header-left">
                <img src="../img/logo.png" class="header-left-logo" alt="" @click="goIndexPage">
                <span @click="goIndexPage">图集</span>
            </div>
            <div class="header-center">
                <div class="header-center-search"></div>
                <input type="text" v-model="searchInput" :placeholder="placeholderTest" @blur="searchInputBlur" @focus="searchInputFocus" @keyup.enter="searchPage">
            </div>
            <div class="header-right">
                <div class="header-right-text">
                    APP
                    <div class="header-qrCode">
                        <!--<img src="../img/deleteQrcode.jpg" alt="">-->
                        <div class="qrcode" ref="qrCodeUrl"></div>
                        <div>扫码下载手机客户端</div>
                    </div>
                </div>
                <div class="header-right-text header-news" v-if="loginStatus">
                    <div class="header-news-img"></div>
                    <div class="header-news-num" v-if="count > 0">{{count}}</div>
                    <div class="header-news-module">
                        <div class="module-head">
                            <div v-for="(item,index) in news" :key="index"
                                 @click="newsSelect(index)"
                                 :class="newsCurrent==index?'active':''">
                                {{item.title}}
                                <div class="circle" v-if="(index === 0 && dataList.length > 0 && !dataList[0].is_read)"></div>
                                <div class="circle" v-if="(index === 1 && money.length > 0) && !money[0].is_read"></div>
                            </div>
                        </div>
                        <div class="module-content" v-if="newsCurrent === 0">
                            <div class="content-list" v-for="item in dataList" :key="'dataList'+item.id">
                                <div class="iconBox">
                                    <img src="../img/logo.png" alt="">
                                    <div class="tag">官方</div>
                                </div>
                                <div class="content-list-info" v-if="item.type == 'painting_accept'">
                                    <div class="money title" :class="item.is_read?'':'active'">
                                        图集审核
                                        <span class="time">{{item.timeStr}}</span>
                                    </div>
                                    <div class="name">
                                        恭喜，您提交的图集
                                        <span style="color: #FA763B;cursor: pointer;" @click.stop="imageBtn(item.painting.id)">【{{item.painting && item.painting.name || ''}}({{item.painting && item.painting.pic_count || ''}})】</span>
                                        已经通过审核。
                                        <span style="color: #FA763B;cursor: pointer;" @click.stop="imageBtn(item.painting.id)">点击查看图集<span class="arrow-right"></span></span>
                                    </div>
                                </div>
                                <div class="content-list-info" v-if="item.type == 'painting_refuse'">
                                    <div class="money title" :class="item.is_read?'':'active'">
                                        图集审核
                                        <span class="time">{{item.timeStr}}</span>
                                    </div>
                                    <div class="name">
                                        非常遗憾，您提交的图集
                                        <span>【{{item.painting && item.painting.name || ''}}({{item.painting && item.painting.pic_count || ''}})】</span>
                                        因为{{item.painting && item.painting.refuse_content || 'xxx'}}原因没有通过审核，图集图片将在12小时后自动删除，请及时备份。
                                    </div>
                                </div>
                                <div class="content-list-info" v-if="item.type == 'painting_is_jp'">
                                    <div class="money title" :class="item.is_read?'':'active'">
                                        精选图集
                                        <span class="time">{{item.timeStr}}</span>
                                    </div>
                                    <div class="name">
                                        恭喜，您提交的图集
                                        <span style="color: #FA763B;cursor: pointer;" @click.stop="imageBtn(item.painting.id)">【{{item.painting && item.painting.name || ''}}({{item.painting && item.painting.pic_count || ''}})】</span>
                                        被评为精选图集，可以在
                                        <span style="color: #000;cursor: pointer;" @click.stop="classBtn(item.painting.category)">【图集首页{{item.painting && item.painting.category && item.painting.category.p_category? '-'+item.painting.category.p_category.name:''}}{{item.painting && item.painting.category? '-'+item.painting.category.name:''}}】</span>
                                        分类中查看。
                                        <span style="color: #FA763B;cursor: pointer;" @click.stop="imageBtn(item.painting.id)">点击查看图集<span class="arrow-right"></span></span>
                                    </div>
                                </div>
                                <div class="content-list-info" v-if="item.type == 'admin_message'">
                                    <div class="money title" :class="item.is_read?'':'active'">
                                        在画官方
                                        <span class="time">{{item.timeStr}}</span>
                                    </div>
                                    <div class="name" v-html="item.content">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="module-content" v-if="newsCurrent === 1">
                            <div class="content-list cursor" v-for="item in money" :key="'money'+ item.id" @click="goOrderBtn">
                                <img
                                        class="image"
                                        v-if="item.money_log.painting_buy.painting"
                                        :src="$uploadUrl + item.money_log.painting_buy.painting.cover + $imageParams"
                                        @click.stop="imageBtn(item.money_log.painting_buy.painting.id)"
                                />
                                <img
                                        class="image"
                                        v-if="!item.money_log.painting_buy.painting"
                                        src="../img/logo.png"
                                />
                                <div class="content-list-info">
                                    <div class="name" :class="item.is_read?'':'active'" v-if="item.type === 'gain' && item.money_log.painting_buy.painting" @click.stop="imageBtn(item.money_log.painting_buy.painting.id)">{{item.money_log.painting_buy.painting.name}}{{item.money_log.painting_buy.painting.pic_count?('('+item.money_log.painting_buy.painting.pic_count+')'):''}}</div>
                                    <div class="drawee" v-if="item.money_log.painting_buy.user" @click.stop="userBtn(item.money_log.painting_buy.user.id)">
                                        付款人：
                                        <div class="drawee-circle"><img :src="item.money_log.painting_buy.user.avatar"/></div>
                                        <span>{{item.money_log.painting_buy.user.nickname}}</span>
                                    </div>
                                    <div class="drawee" v-if="!item.money_log.painting_buy.user">
                                        付款人：
                                        <div class="drawee-circle"><img src="../img/logo.png"/></div>
                                        <span>在画官方</span>
                                    </div>
                                    <div class="money">
                                        收入金额：
                                        <span class="value">金瓜子{{item.money_log.money>=0?'+'+item.money_log.money:item.money_log.money}}</span>
                                        <span class="time">{{item.timeStr}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="module-foot active" @click="readBtn">
                            全部已读
                        </div>
                    </div>
                </div>
                <div class="header-right-text header-release" @click="uploadClick">
                    <div class="header-right-upload"></div>
                    发布
                    <div class="release-module">
                        <div class="release-module-list" @click="newAtlasBtn">
                            <div class="module-list-img newAtlas"></div>
                            新建图集
                        </div>
                    </div>
                </div>
                <!-- 未登录 -->
                <div class="header-right-text" @click="login" v-if="loginStatus==false">注册|登录</div>
                <!-- 已登录 -->
                <div class="header-right-text header-head" v-if="loginStatus">
                    <img :src="userinfo.avatar" alt="">
                    <div class="head-module" v-if="modalShow">
                        <div class="head-module-list"
                             v-for="(item,index) in myList" :key="index"
                             @click="myListClick(item)">
                            <div class="module-list-img" :class="item.className"></div>
                            {{item.text}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<loginComponent-->
                <!--:show="loginShow"-->
                <!--@onCloseClick="onCloseClick"-->
                <!--@onForgetClick="onForgetClick"-->
        <!--&gt;</loginComponent>-->
        <loginComponent
                :show="$store.state.isLogin"
                @onCloseClick="onCloseClick"
                @onForgetClick="onForgetClick"
        ></loginComponent>
        <authentication
                v-show="forgetShow"
                :title="'找回密码'"
                @onCloseClick="onForgetCloseClick"
                @onButtonClick="onForgetButtonClick"
        ></authentication>
        <authentication
                v-show="passShow"
                :title="'修改密码'"
                @onCloseClick="onPassCloseClick"
                @onButtonClick="onPassButtonClick"
        ></authentication>
        <!--绑定手机号-->
        <authentication
                v-show="bindMobileShow"
                :title="'绑定手机号'"
                @onCloseClick="onBindMobileCloseClick"
                @onButtonClick="onBindMobileButtonClick"
        ></authentication>
        <!-- 支付组件 -->
        <payment ref="payment_header" :title="''" @tipBtnClick="tipBtnClick"></payment>
        <!--充值协议-->
        <myTip :show="myTipShow"
               title="充值服务协议"
               @onCloseClick="onTipCloseClick"
               @onButtonClick="onTipCloseClick">
        </myTip>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2'

    export default {
        data() {
            return {
                placeholderTest:'画速写，上在画速写',
                searchInput: "",
                loginStatus: false,
                userinfo: null,
                myList: [
                    {
                        className: "myRedraw",
                        text: "我的在画"
                    },
                    {
                        className: "recharge",
                        text: "充值"
                    },
                    {
                        className: "order",
                        text: "订单"
                    },
                    {
                        className: "set",
                        text: "设置"
                    },
                    {
                        className: "signOut",
                        text: "退出"
                    }
                ],
                news: [
                    {title: "系统消息"},
                    {title: "获得收入"},
                ],
                newsCurrent: 0,
                loginShow: false,
                forgetShow: false,
                passShow: false,

                count:0,
                dataList:[],
                money:[],

                myTipShow:false,

                modalShow:true, //我的菜单兼容ipad点击

                bindMobileShow:false, //绑定手机号
                type:'wechat',
                openid_wx:'',
                unionid_wx:'',
                openid_qq:'',
            }
        },
        mounted() {
            if(this.getUrlData('code')){
                if(this.getUrlData('type') === 'qqLogin'){
                    this.refreshQQLoginData({
                        code:this.getUrlData('code')
                    },(data)=>{
                        if(data.code === 0){
                            return
                        }
                        // data = {
                        //     "code": 1,
                        //     "msg": "登录成功",
                        //     "time": "1663570375",
                        //     "data": {
                        //         "userinfo": {
                        //             "id": 772,
                        //             "username": "15531500747",
                        //             "nickname": "¥ㄟ(´･ᴗ･`)ノ$养乐多",
                        //             "mobile": "15531500747",
                        //             "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJjvaAqWxb7Ylo2N5ibgSsQFPZo7dcrbicianvX87WnH8uj3PRvzwSPSMN1tUHwvc4gSib8lP5n0rxyJg/132",
                        //             "score": 0,
                        //             "token": "5928555a-7686-45ae-a1bb-ba93417565d8",
                        //             "user_id": 772,
                        //             "createtime": 1663570375,
                        //             "expiretime": 1666162375,
                        //             "expires_in": 2592000
                        //         },
                        //         "access_token": "60_a_8RYqCXAsj5o3K1PCE4Z3hCNrCq-Msb2WeVcVf6RnI8ZZMoTVVZjrshzYtHzLlxVdPakH1v3M9vL8vTuMnzgA6uzAxAjWwI8SiiBYispdQ",
                        //         "wechat_data": {
                        //             "openid": "odBiZ6P_bH1_UZZjXkQy1dyTmDIU",
                        //             "nickname": "¥ㄟ(´･ᴗ･`)ノ$养乐多",
                        //             "sex": 0,
                        //             "language": "",
                        //             "city": "",
                        //             "province": "",
                        //             "country": "",
                        //             "headimgurl": "https:\/\/thirdwx.qlogo.cn\/mmopen\/vi_32\/Q0j4TwGTfTJkh91bKfFgzSus5k9ibowpeBHmYlXkI8icDvQTg59T9xGlwQWf9FTB7vXGQoka4roVjLkLu32joqsw\/132",
                        //             "privilege": [],
                        //             "unionid": "ovXaH6HVb9gTkZA3HdLTA5varXXg"
                        //         }
                        //     }
                        // };
                        console.log(data);

                        if(data.data.userinfo && data.data.userinfo.token){
                            localStorage.setItem("token",data.data.userinfo.token);
                            localStorage.setItem("userinfo",JSON.stringify(data.data.userinfo));
                            location.search = '';
                        }else{
                            this.bindMobileShow = true;
                            this.type = 'qq';
                            this.openid_qq = data.data.access_token;
                        }
                    });
                }else{
                    this.refreshWechatLoginData({
                        code:this.getUrlData('code')
                    },(data)=>{
                        if(data.code === 0){
                            return
                        }
                        // data = {
                        //     "code": 1,
                        //     "msg": "登录成功",
                        //     "time": "1663570375",
                        //     "data": {
                        //         "userinfo": {
                        //             "id": 772,
                        //             "username": "15531500747",
                        //             "nickname": "¥ㄟ(´･ᴗ･`)ノ$养乐多",
                        //             "mobile": "15531500747",
                        //             "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJjvaAqWxb7Ylo2N5ibgSsQFPZo7dcrbicianvX87WnH8uj3PRvzwSPSMN1tUHwvc4gSib8lP5n0rxyJg/132",
                        //             "score": 0,
                        //             "token": "5928555a-7686-45ae-a1bb-ba93417565d8",
                        //             "user_id": 772,
                        //             "createtime": 1663570375,
                        //             "expiretime": 1666162375,
                        //             "expires_in": 2592000
                        //         },
                        //         "access_token": "60_a_8RYqCXAsj5o3K1PCE4Z3hCNrCq-Msb2WeVcVf6RnI8ZZMoTVVZjrshzYtHzLlxVdPakH1v3M9vL8vTuMnzgA6uzAxAjWwI8SiiBYispdQ",
                        //         "wechat_data": {
                        //             "openid": "odBiZ6P_bH1_UZZjXkQy1dyTmDIU",
                        //             "nickname": "¥ㄟ(´･ᴗ･`)ノ$养乐多",
                        //             "sex": 0,
                        //             "language": "",
                        //             "city": "",
                        //             "province": "",
                        //             "country": "",
                        //             "headimgurl": "https:\/\/thirdwx.qlogo.cn\/mmopen\/vi_32\/Q0j4TwGTfTJkh91bKfFgzSus5k9ibowpeBHmYlXkI8icDvQTg59T9xGlwQWf9FTB7vXGQoka4roVjLkLu32joqsw\/132",
                        //             "privilege": [],
                        //             "unionid": "ovXaH6HVb9gTkZA3HdLTA5varXXg"
                        //         }
                        //     }
                        // };
                        console.log(data);

                        if(data.data.userinfo && data.data.userinfo.token){
                            localStorage.setItem("token",data.data.userinfo.token);
                            localStorage.setItem("userinfo",JSON.stringify(data.data.userinfo));
                            location.search = '';
                        }else{
                            this.bindMobileShow = true;
                            this.type = 'wechat';
                            this.openid_wx = data.data.wechat_data.openid;
                            this.unionid_wx = data.data.wechat_data.unionid;
                        }
                    });
                }
            }

            this.userinfo = JSON.parse(localStorage.getItem("userinfo") || '{}');
            this.userinfo.id ? this.loginStatus = true : this.loginStatus = false;
            this.creatQrCode();
            this.refreshMessageNumData();
            this.refreshSystemMessageListData();
            this.refreshMoneyMessageListData();

            this.cutInLog();
        },
        methods: {
            creatQrCode() {
                // console.log(QRCode);
                new QRCode(this.$refs.qrCodeUrl, {
                    text: 'http://h5.zaihua.art/#/pages/download/download', // 需要转换为二维码的内容
                    width: 100,
                    height: 100,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
            newsSelect(index) {
                this.newsCurrent = index
            },
            // 去首页
            goIndexPage() {
                this.$router.push({path: "/"})
            },
            // 综合搜索
            searchPage() {
                this.$store.state.keyWords = this.searchInput;
                let path = this.$route.path.substring(0, 7);
                if (path !== "/search") {
                    this.$router.push({path: "/search/"})
                }
            },
            // 发布
            uploadClick() {
                if (!this.userinfo) {
                    this.login();
                }
            },
            // 新建图集
            newAtlasBtn(){
                this.$router.push({path: '/newAtlas'})
            },
            // 打开登录窗
            login() {
                // this.loginShow = true;
                this.$store.state.isLogin = true;
            },
            // 关闭弹窗
            onCloseClick() {
                // this.loginShow = false;
                this.$store.state.isLogin = false;
            },
            //忘记密码按钮
            onForgetClick(){
                this.forgetShow = true;
            },
            //忘记密码关闭按钮
            onForgetCloseClick(){
                this.forgetShow = false;
            },
            //忘记密码成功按钮
            onForgetButtonClick(userinfo,code,mobile){
                let opt = {
                    mobile:mobile,
                    captcha:code,
                };
                opt.type = 'mobile';
                this.$http.get("/api/user/resetpwd",{
                    params:opt
                }).then((res)=>{
                    if(res.code === 0){
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        });
                    }else{
                        this.forgetShow = false;
                        this.passShow = true;
                        localStorage.setItem("token",res.data.userinfo.token);
                    }
                });
            },
            //修改密码关闭按钮
            onPassCloseClick(){
                this.passShow = false;
            },
            //修改密码成功按钮
            onPassButtonClick(userinfo,code,mobile,pass1,pass2){
                if(!pass1 || !pass2){
                    this.$message({
                        message: '请设置新密码',
                        type: 'warning'
                    });
                    return
                }
                if(pass1 !== pass2){
                    this.$message({
                        message: '两次密码不一致',
                        type: 'warning'
                    });
                    return
                }
                let opt = {
                    newpassword:pass1,
                };
                this.$http.get("/api/user/updatepwd",{
                    params:opt
                }).then(()=>{
                    this.passShow = false;
                    this.login();
                });
            },
            // 我的在画操作
            myListClick(item) {
                this.modalShow = false;
                setTimeout(()=>{
                    this.modalShow = true;
                },200);
                if (item.className === "myRedraw") {
                    this.$router.push({path: '/userPerspective'})
                }
                if (item.className === "recharge") {
                    this.$refs.payment_header.paymentShow = true;
                }
                if (item.className === "order") {
                    this.$router.push({path: '/order/0'})
                }
                if (item.className === "set") {
                    this.$router.push({path: '/settings'})
                }
                if (item.className === "signOut") {
                    localStorage.removeItem("userinfo");
                    localStorage.removeItem("token");
                    this.$router.push({path: "/"});
                    location.reload();
                }
            },
            //收入消息点击事件
            goOrderBtn(){
                this.$router.push({path: '/order/1'})
            },
            //图集按钮
            imageBtn(id){
                this.$router.push({path:`/unlockAtlas/${id}`});
            },
            //用户按钮
            userBtn(id){
                this.$router.push({path:`/userPerspectiveOther/${id}`});
            },
            //图集分类按钮
            classBtn(item){
                this.$router.push({path:`/allCategories/0/${item && item.pid || 0}/${item && item.id || 0}`});
            },
            //全部已读按钮
            readBtn(){
                let successFun = ()=>{
                    this.refreshMessageNumData();
                };
                if(this.newsCurrent === 0){
                    this.$http.get("/api/user/get_my_message",{
                        params:{
                            type:['admin_message', //系统消息
                                'painting_accept', //审核通过
                                'painting_refuse', //审核拒绝
                                'painting_is_jp',  //审核精品
                            ].join(','),
                            is_read:true,
                            limit:9999,
                        }
                    }).then(()=>{
                        successFun();
                        this.refreshSystemMessageListData();
                    });
                }else if(this.newsCurrent === 1){
                    this.$http.get("/api/user/get_my_message",{
                        params:{
                            type:['gain'].join(','),
                            is_read:true,
                            limit:9999,
                        }
                    }).then(()=>{
                        successFun();
                        this.refreshMoneyMessageListData();
                    });
                }
            },
            //消息数量
            refreshMessageNumData(){
                this.$http.get("/api/user/get_my_message",{
                    params:{
                        is_read:false
                    }
                }).then((data)=>{
                    this.count = data && data.data && data.data.length || 0;
                })
            },
            //系统消息
            refreshSystemMessageListData:function(){
                var _this = this;
                this.$http.get("/api/user/get_my_message",{
                    params:{
                        type:['admin_message', //系统消息
                            'painting_accept', //审核通过
                            'painting_refuse', //审核拒绝
                            'painting_is_jp',  //审核精品
                        ].join(','),
                        // is_read:false,
                        limit:100,
                    }
                }).then((data)=>{
                    if(data && data.data){
                        for(let i = 0;i<data.data.length;i++){
                            let date = new Date(data.data[i].createtime*1000);
                            data.data[i].timeStr = (date.getMonth() + 1) + '-' +
                                (date.getDate()<10?'0'+date.getDate():date.getDate()) + ' ' +
                                (date.getHours()<10? '0'+date.getHours():date.getHours()) + ':' +
                                (date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes());

                        }
                    }

                    // let arr = data.data;
                    // for(let i = 0;i<arr.length;i++){
                    //     if(arr[i].type === 'painting_accept' || arr[i].type === 'painting_refuse' || arr[i].type === 'painting_is_jp'){
                    //         arr[i].content += '<span style="color: #FA763B">点击查看图集></span>'
                    //     }
                    // }
                    _this.dataList = data && data.data || [];
                });
            },
            //获得收入
            refreshMoneyMessageListData:function(){
                var _this = this;
                // this.$http.get("/api/user/getUserMoneyLog",{
                //     params:{
                //         type:'admin,gain,recharge',
                //         // is_read:false,
                //         limit:9999,
                //     }
                // })
                this.$http.get("/api/user/get_my_message",{
                    params:{
                        type:'gain',
                        // is_read:false,
                        limit:100,
                    }
                }).then((data)=>{
                    for(var i = 0;i<data.data.length;i++){
                        let date = new Date(data.data[i].createtime * 1000);
                        data.data[i].timeStr = ((date.getMonth()+1)<10?'0'+(date.getMonth()+1):(date.getMonth()+1)) + '.' +
                            (date.getDate()<10?'0'+date.getDate():date.getDate());
                    }
                    _this.money = data.data;
                });
            },

            //充值协议
            tipBtnClick(){
                this.myTipShow = true;
            },
            onTipCloseClick(){
                this.myTipShow = false;
            },

            //获取URL中的参数
            getUrlData(key){
                let value = '';
                let href = location.href;
                let dataStr = href.split('?')[1];
                if(dataStr){
                    let arr = dataStr.split('&');
                    for(let i = 0;i<arr.length;i++){
                        if(key === arr[i].split('=')[0]){
                            value = arr[i].split('=')[1];
                        }
                    }
                }
                return value;
            },

            //微信登录
            refreshWechatLoginData(opt,fun){
                this.$http.get("/api/user/wechatpclogin",{
                    params:opt
                }).then((data)=>{
                    if(fun){fun(data)}
                })
            },
            //QQ登录
            refreshQQLoginData(opt,fun){
                this.$http.get("/api/user/qqpclogin",{
                    params:opt
                }).then((data)=>{
                    if(fun){fun(data)}
                })
            },

            //绑定手机号关闭按钮
            onBindMobileCloseClick(){
                this.bindMobileShow = false;
            },
            //绑定手机号确定按钮按钮
            onBindMobileButtonClick(userinfo,code,mobile){
                this.$http.get("/api/user/mobilelogin",{
                    params:{
                        mobile:mobile,
                        captcha:code
                    }
                }).then((res)=>{
                    if(res.data&&res.data.userinfo.token){
                        localStorage.setItem("token",res.data.userinfo.token);
                        localStorage.setItem("userinfo",JSON.stringify(res.data.userinfo));
                        if(this.type === 'wechat'){
                            this.$http.get("/api/user/profile",{
                                params:{
                                    openid:this.openid_wx,
                                    unionid:this.unionid_wx,
                                }
                            }).then((res)=>{
                                console.log(res);
                            })
                        }else if(this.type === 'qq'){
                            this.$http.get("/api/user/profile",{
                                params:{
                                    qq_access_token:this.openid_qq,
                                }
                            }).then((res)=>{
                                console.log(res);
                            })
                        }
                        this.bindMobileShow = false;
                        location.reload();
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        });
                    }
                })
            },

            //切入，其实就是访问记录
            cutInLog(){
                if(this.userinfo.id){
                    let opt = {
                        channel:'pc',
                        web_channel:'pc',
                    };
                    this.$http.get("/api/user/add_user_cut_in_log",{
                        params:opt
                    }).then(()=>{});
                }
            },

            searchInputBlur(){
                this.placeholderTest = '画速写，上在画速写';
            },
            searchInputFocus(){
                this.placeholderTest = '';
            },
        }
    }
</script>

<style>

</style>