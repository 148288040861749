<template>
    <!-- 图集-解锁图集 -->
    <div id="unlockAtlas">
        <div class="section">
            <div class="section-title">
                <div class="pcClass">
                    <div class="left">
                        <div class="title-info">
                            {{data?data.name:''}}（{{data?data.pic_count:0}}）
                            <div class="title-info-choice vip" v-if="data.channel === 'admin' && data.is_vip === 1">VIP</div>
                            <div class="title-info-choice selected" v-if="data.channel === 'user' && data.status === 'accept' && data.is_jp">精选</div>
                            <div class="title-info-choice free" v-if="data.channel === 'admin' && data.is_vip === 0">免费</div>
                        </div>
                        <!--<div class="title-follow" :style="{'visibility':data.channel === 'user'?'visible':'hidden'}">-->
                        <div class="title-follow" v-if="data.channel === 'user'">
                            <img :src="data&&data.user&&data.user.avatar?data.user.avatar:''" alt="" @click="userBtn(data.user.id)">
                            <div class="title-follow-name" @click="userBtn(data.user.id)">{{data&&data.user?data.user.nickname:''}}</div>
                            <!-- 已关注加active -->
                            <div class="title-follw-text" :class="data&&data.user&&data.user.is_follow?'active':''" @click="followBtn">关注</div>
                        </div>
                    </div>
                    <div class="title-operation">
                        <div class="title-operation-module module-look">
                            <div class="img"></div>
                            <span>{{data?data.read_count:0}} 查看</span>
                        </div>
                        <div class="title-operation-module module-collection" 
                            @click="collectionClick"
                            :class="data&&data.is_like?'active':''">
                            <div class="img"></div>
                            <span>{{data?data.like_count:0}} 收藏</span>
                        </div>
                        <div class="title-operation-module module-share selected">
                            <div class="img"></div>
                            <span>{{data?data.share_count:0}} 分享</span>
                            <div class="module-share-channel">
                                <div class="share-channel-list" 
                                    v-for="(item,index) in shareChannel" :key="index"
                                    :class="item.className"
                                    @click="shareBtn(item)">
                                    <div class="channel-list-img"></div>
                                    <div class="channel-list-type">{{item.type}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="title-operation-module module-more">
                            <div class="img"></div>
                            <div class="module-more-operation">
                                <div class="operation-list" 
                                    v-for="(item,index) in moreOperation" :key="index"
                                    :class="item.className"
                                    @click="moreOperationClick(item.text)" v-show="item.className === 'deleteAtlas'? userInfo.id && data.user && userInfo.id === data.user.id:true">
                                    <div class="operation-img"></div>
                                    {{item.text}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 图集组件 -->
        <myAtlas page="unlockAtlas" :myAtlasList="myAtlasList"></myAtlas>
        <!-- 关注解锁图集组件 -->
        <myAside v-if="userInfo.id !== data.user.id && !data.is_buy && !data.is_vip && (data.user && !data.user.is_follow)" title="关注用户并支付2个金瓜子解锁该图集" @onButtonClick="unlockAtlas"></myAside>
        <myAside v-if="userInfo.id !== data.user.id && !data.is_buy && !data.is_vip && (data.user && data.user.is_follow)" title="支付2个金瓜子解锁该图集" @onButtonClick="unlockAtlas"></myAside>
        <!-- 图集举报组件 -->
        <report :show="reportShow" @onCancel="onCancel" @onConfirm="onConfirm"></report>
        <!-- 删除图集组件 -->
        <deleteAtlas :show="deleteAtlasShow" 
            @onCancelDelete="onCancelDelete" 
            @onConfirmDelete="onConfirmDelete">
        </deleteAtlas>
        <!-- 提示 -->
        <myBlackTip :text="myBlackTip.successTitle" v-show="myBlackTip.show"></myBlackTip>
        <!-- 解锁图集组件 -->
        <myTip :show="myTipShow"
               title="解锁图集"
               :text="!data.user.is_follow?'关注用户并支付2个金瓜子解锁该图集':'支付2个金瓜子解锁该图集'"
               @onCloseClick="onCloseClick"
               @onButtonClick="onButtonClick">
        </myTip>
        <!-- 支付组件 -->
        <payment ref="payment" @onCompleteFun="completeFun"></payment>
    </div>
</template>

<script>
    import store from "../store/index.js";
export default {
    data(){
        return{
            oId:{},
            shareChannel:[
                {
                    className:"qq",
                    type:"QQ"
                },
                {
                    className:"qqZone",
                    type:"QQ空间"
                },
                {
                    className:"microBlog",
                    type:"微博"
                }
            ],
            moreOperation:[
                {
                    className:"allCategories",
                    text:"全部分类"
                },
                {
                    className:"complaintsReport",
                    text:"投诉举报"
                },
                {
                    className:"deleteAtlas",
                    text:"删除图集"
                }
            ],
            reportShow:false,
            deleteAtlasShow:false,
            data:{
                user:{}
            },
            userInfo:{},
            myAtlasList:[],     //图集列表
            myBlackTip:{
                successTitle:"举报成功",
                show:false
            },
            myTipShow:false,
        }
    },
    mounted(){
        this.oId=this.$route.params.id;
        this.deleteRed();
        this.viewAtlasData();
        this.refreshUserInfoData(()=>{
            this.lookAtlas();
        });
    },
    methods:{
        moreOperationClick(text){
            if(text==="全部分类"){
                this.$router.push({ path: "/allCategories/0/0/0"})
            }
            if(text==="投诉举报"){
                this.reportShow=true
            }
            if(text==="删除图集"){
                this.deleteAtlasShow=true
            }
        },
        // 取消举报
        onCancel(){
            this.reportShow=false;
        },
        // 确定举报
        onConfirm(type,describe,fun){
            if(!type||!describe){
                this.$message({
                    showClose: true,
                    message: '举报类型或内容不能为空',
                    type: 'warning'
                });
                return;
            }
            this.$http.get("/api/painting/report_painting",{
                params:{
                    painting_id:this.oId,
                    type:type,
                    content:describe
                }
            }).then(()=>{
                this.reportShow=false;
                this.myBlackTip.successTitle="举报成功";
                this.myBlackTip.show=true;
                setTimeout(()=>{
                    this.myBlackTip.show=false;
                    if(fun){
                        fun();
                    }
                },2000);
            })
        },
         // 取消删除
        onCancelDelete(){
            this.deleteAtlasShow=false;
        },
        // 确定删除
        onConfirmDelete(){
            this.deleteAtlasShow=false;
            this.$http.get("/api/painting/delete_painting",{
                params:{
                    painting_id:this.oId
                }
            }).then(()=>{
                this.deleteAtlas=false;
                this.myBlackTip.successTitle="删除成功";
                this.myBlackTip.show=true;
                setTimeout(()=>{
                    this.myBlackTip.show=false;
                    this.$router.push({path:"/"})
                },2000)
            })
        },
        // 清除小红点
        deleteRed(){
            this.$http.get("/api/painting/delete_painting_red",{
                params:{
                    id:this.oId
                }
            }).then(()=>{})
        },
        // 查看图集
        lookAtlas(){
            if(this.userInfo.id){
                this.$http.get("/api/painting/read_painting",{
                    params:{
                        painting_id:this.oId
                    }
                }).then(()=>{
                    this.viewAtlasData()
                })
            }
            // if(this.userInfo.id !== this.data.user.id && !this.data.is_buy && !this.data.is_vip){
            //     this.myTipShow=true;
            // }else{
            //
            // }
        },
        // 查询图集
        viewAtlasData(){
            this.$http.get("/api/painting/get_painting_id",{
                params:{
                    id:this.oId
                }
            }).then((res)=>{
                var list=res.data.pic.split(',');
                this.myAtlasList = [];
                for(let i=0;i<list.length;i++){
                    this.myAtlasList.push({
                        cover:list[i],
                        is_buy:!(this.userInfo.id !== res.data.user.id && !res.data.is_buy && !res.data.is_vip),
                        id:this.oId
                    })
                }
                this.data=res.data;
            })
        },
        // 收藏图集
        collectionClick(){
            if(!this.getToken()){
                return
            }
            this.$http.get("/api/painting/like_painting",{
                params:{
                    painting_id:this.oId
                }
            }).then(()=>{
                this.viewAtlasData();
            })
        },
        //用户按钮
        userBtn(id){
            this.$router.push({path:`/userPerspectiveOther/${id}`});
        },
        // 关注用户
        followBtn(){
            this.$http.get("/api/user/follow_user",{
                params:{
                    user_id:this.data.user.id
                }
            }).then(()=>{
                this.viewAtlasData();
            })
        },
        //分享按钮
        shareBtn(item){
            let shareUrl = 'http://h5.zaihua.art/#/?id=' + this.oId;
            let title = this.data.name;
            let summary = '上万张速写参考！支持本地素材上传！支持图片轮播倒计时！';
            switch (item.className) {
                case 'qq':
                    window.open(this.$shareUrl + this.oId);
                    break;
                case 'qqZone':
                    window.open(
                        "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
                        encodeURIComponent(shareUrl) +
                        "&title=" + title + "&summary=" + summary
                    );
                    break;
                case 'microBlog':
                    window.open(
                        "https://service.weibo.com/share/share.php?url=" +
                        encodeURIComponent(shareUrl) +
                        "&title=" + title
                    );
                    break;
            }
        },
        // 解锁图集
        unlockAtlas(){
            // this.myTipShow=true;
            this.onButtonClick();
        },
        // 关闭弹窗
        onCloseClick(){
            this.myTipShow=false
        },
        // 确定按钮
        onButtonClick(){
            if(!localStorage.getItem("token")){
                store.commit('setIsLogin',true);
                return
            }
            if(this.userInfo.money*1 >= 2){
                this.refreshBuyData(()=>{
                    this.viewAtlasData();
                    this.myTipShow=false;
                    this.$message({
                        message: '图集已解锁',
                        type: 'success'
                    });
                });
            }else{
                this.$refs.payment.paymentShow = true;
            }
        },
        //支付完成执行的方法
        completeFun(){
            var _this = this;
            _this.refreshUserInfoData(()=>{
                this.refreshBuyData(()=>{
                    this.viewAtlasData();
                    this.myTipShow=false;
                    this.$message({
                        message: '图集已解锁',
                        type: 'success'
                    });
                });
            });
        },

        // 刷新个人信息
        refreshUserInfoData(fun){
            this.$http.get("/api/user/getUser",{
                params:{}
            }).then((res)=>{
                this.userInfo = res && res.data || {};
                if(fun){
                    fun();
                }
            })
        },
        // 购买图集
        refreshBuyData(fun){
            let _this = this;
            this.$http.get("/api/painting/buy_painting",{
                params:{
                    painting_id:_this.oId,
                    user_id:_this.userInfo.id
                }
            }).then(()=>{
                if(fun){
                    fun();
                }
            });
        },
    }
}
</script>

<style>

</style>