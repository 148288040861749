<template>
    <!-- 我的_编辑资料_填写资料 -->

    <div id="fillInInformation">
        <div class="edit">
            <div class="edit-word">编辑资料</div>
            <el-upload
                    class="imageBox"
                    action=""
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="onChange"
            >
                <div class="edit-head">
                    <img :src="userInfo.avatar"/>
                    <div class="edit-head-camera"></div>
                </div>
                <p class=edit-click>点击更换头像</p>
            </el-upload>
            <div class="content">
                <div class="name">
                    <div class="name-left">名字</div>
                    <div class="name-right" v-show="!nameEditing && !userInfo.nickname" @click="editingMaterials('nameEditing')">
                        <img src="../img/pen.png" alt="" class="name-pen">
                        <input type="text" class="test" placeholder="点击填写" style="pointer-events: none;">
                    </div>
                    <div class="name-right" v-show="!nameEditing && userInfo.nickname">
                        <div class="test">{{userInfo.nickname}}</div>
                        <div class="move" @click="editingMaterials('nameEditing')">
                            <img src="../img/color-pen.png" alt=""><span class="fl">编辑</span>
                        </div>
                    </div>
                    <div class="name-right-edit" v-show="nameEditing">
                        <div class="input">
                            <el-input v-model="nameContent" placeholder="请输入名字" class="nameInput"></el-input>
                            <!--<input type="text" class="nameInput" placeholder="请输入名字" v-model="nameContent">-->
                        </div>
                        <div class="button">
                            <div class="cancel" @click="editingCancelMaterials('nameEditing')">取消</div>
                            <div class="preservation" @click="saveBtnName">保存</div>
                        </div>
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">性别</div>
                    <div class="name-right" v-show="!sexEditing">
                        <span class="test" v-if="userInfo.gender === 1">男</span>
                        <span class="test" v-if="userInfo.gender === 0">女</span>
                        <span class="test" v-if="userInfo.gender === 2">保密</span>
                        <div class="move" @click="editingMaterials('sexEditing')">
                            <img src="../img/color-pen.png" alt=""><span class="fl">编辑</span>
                        </div>
                    </div>
                    <div class="name-right-edit" v-show="sexEditing">
                        <div class="input">
                            <div class="input-sex" @click="selectSex(1)" :class="sex===1?'active':''">
                                <div class="input-sex-man">
                                    <div class="circle"></div>
                                </div>
                                男
                            </div>
                            <div class="input-sex" @click="selectSex(0)" :class="sex===0?'active':''">
                                <div class="input-sex-woman">
                                    <div class="circle"></div>
                                </div>
                                女
                            </div>
                            <div class="input-sex" @click="selectSex(2)" :class="sex===2?'active':''">
                                <div class="input-sex-woman">
                                    <div class="circle"></div>
                                </div>
                                保密
                            </div>
                        </div>
                        <div class="button">
                            <div class="cancel" @click="editingCancelMaterials('sexEditing')">取消</div>
                            <div class="preservation" @click="saveBtnSex">保存</div>
                        </div>
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">微信号</div>
                    <div class="name-right" v-show="!weChatEditing && !userInfo.weChat" @click="editingMaterials('weChatEditing')">
                        <img src="../img/pen.png" alt="" class="name-pen">
                        <input type="text" class="test" placeholder="点击填写" style="pointer-events: none;">
                    </div>
                    <div class="name-right" v-show="!weChatEditing && userInfo.weChat">
                        <span class="test">{{userInfo.weChat}}</span>
                        <div class="move" @click="editingMaterials('weChatEditing')">
                            <img src="../img/color-pen.png" alt=""><span class="fl">编辑</span>
                        </div>
                    </div>
                    <div class="name-right-edit" v-show="weChatEditing">
                        <div class="input">
                            <el-input v-model="weChatContent" placeholder="请输入微信号" class="nameInput"></el-input>
                            <!--<input type="text" class="nameInput" placeholder="请输入微信号" v-model="weChatContent">-->
                        </div>
                        <div class="button">
                            <div class="cancel" @click="editingCancelMaterials('weChatEditing')">取消</div>
                            <div class="preservation" @click="saveBtnWeChat">保存</div>
                        </div>
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">生日</div>
                    <div class="name-right" v-show="!dateEditing && !userInfo.birthday" @click="editingMaterials('dateEditing')">
                        <img src="../img/pen.png" alt="" class="name-pen">
                        <input type="text" class="test" placeholder="点击填写" style="pointer-events: none;">
                    </div>
                    <div class="name-right" v-show="!dateEditing && userInfo.birthday">
                        <span class="test">{{userInfo.birthday}}</span>
                        <div class="move" @click="editingMaterials('dateEditing')">
                            <img src="../img/color-pen.png" alt=""><span class="fl">编辑</span>
                        </div>
                    </div>
                    <div class="name-right-edit" v-show="dateEditing">
                        <div class="input">
                            <dir class="input-date">
                                <el-date-picker
                                        class="nameInput"
                                        v-model="dateValue"
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                        placeholder="清选择日期">
                                </el-date-picker>
                                <!--<input type="text" class="nameInput year" placeholder="1999"><span>年</span>-->
                                <!--<input type="text" class="nameInput month" placeholder="12"><span>月</span>-->
                                <!--<input type="text" class="nameInput day" placeholder="22"><span>日</span>-->
                            </dir>
                        </div>
                        <div class="button">
                            <div class="cancel" @click="editingCancelMaterials('dateEditing')">取消</div>
                            <div class="preservation" @click="saveBtnDate">保存</div>
                        </div>
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">地区</div>
                    <div class="name-right" v-show="!addressEditing && !userInfo.area" @click="editingMaterials('addressEditing')">
                        <img src="../img/pen.png" alt="" class="name-pen">
                        <input type="text" class="test" placeholder="点击填写" style="pointer-events: none;">
                    </div>
                    <div class="name-right" v-show="!addressEditing && userInfo.area">
                        <span class="test">{{userInfo.area}}</span>
                        <div class="move" @click="editingMaterials('addressEditing')">
                            <img src="../img/color-pen.png" alt=""><span class="fl">编辑</span>
                        </div>
                    </div>
                    <div class="name-right-edit" v-show="addressEditing">
                        <div class="input">
                            <el-cascader
                                    size="large"
                                    :options="options"
                                    v-model="selectedOptions"
                                    @change="handleChange"
                            >
                            </el-cascader>
                            <!--<input type="text" class="nameInput" placeholder="北京-大兴">-->
                        </div>
                        <div class="button">
                            <div class="cancel" @click="editingCancelMaterials('addressEditing')">取消</div>
                            <div class="preservation" @click="saveBtnAddress">保存</div>
                        </div>
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">简介</div>
                    <div class="name-right" v-show="!descEditing && !userInfo.bio" @click="editingMaterials('descEditing')">
                        <img src="../img/pen.png" alt="" class="name-pen">
                        <input type="text" class="test" placeholder="点击填写" style="pointer-events: none;">
                    </div>
                    <div class="name-right" v-show="!descEditing && userInfo.bio">
                        <span class="test">{{userInfo.bio}}</span>
                        <div class="move" @click="editingMaterials('descEditing')">
                            <img src="../img/color-pen.png" alt=""><span class="fl">编辑</span>
                        </div>
                    </div>
                    <div class="name-right-edit" v-show="descEditing">
                        <div class="content-describe">
                            <textarea placeholder="填写个人简介更容易交到朋友哦"
                                      v-model="describe"
                                      maxlength="100">
                            </textarea>
                            <div class="content-describe-font">{{describe.length}}/100</div>
                        </div>
                        <div class="button">
                            <div class="cancel" @click="editingCancelMaterials('descEditing')">取消</div>
                            <div class="preservation" @click="saveBtnDesc">保存</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 剪裁组件弹窗 -->
        <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body>
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                    <vueCropper
                            ref="cropper"
                            :img="option.img"
                            :outputSize="option.size"
                            :outputType="option.outputType"
                            :info="true"
                            :full="option.full"
                            :canMove="option.canMove"
                            :canMoveBox="option.canMoveBox"
                            :original="option.original"
                            :autoCrop="option.autoCrop"
                            :fixed="option.fixed"
                            :fixedNumber="option.fixedNumber"
                            :centerBox="option.centerBox"
                            :infoTrue="option.infoTrue"
                            :fixedBox="option.fixedBox"
                    ></vueCropper>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { regionData, CodeToText, TextToCode } from "element-china-area-data";
    export default {
        data() {
            return {
                userInfo: {
                    nickname:' ',
                    gender:' ',
                    weChat:' ',
                    birthday:' ',
                    area:' ',
                    bio:' ',
                },

                nameEditing: false,
                sexEditing: false,
                weChatEditing: false,
                dateEditing: false,
                addressEditing: false,
                descEditing: false,

                dialogVisible: false, // 剪裁组件弹窗开关
                option: {
                    img: '', // 裁剪图片的地址
                    info: true, // 裁剪框的大小信息
                    outputSize: 0.8, // 裁剪生成图片的质量
                    outputType: 'jpeg', // 裁剪生成图片的格式
                    canScale: false, // 图片是否允许滚轮缩放
                    autoCrop: true, // 是否默认生成截图框
                    autoCropWidth: 200, // 默认生成截图框宽度
                    autoCropHeight: 200, // 默认生成截图框高度
                    fixedBox: true, // 固定截图框大小 不允许改变
                    fixed: true, // 是否开启截图框宽高固定比例
                    fixedNumber: [5, 5], // 截图框的宽高比例
                    full: true, // 是否输出原图比例的截图
                    canMoveBox: false, // 截图框能否拖动
                    original: false, // 上传图片按照原始比例渲染
                    centerBox: true, // 截图框是否被限制在图片里面
                    infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                },
                loading: false,

                nameContent: '',

                sex: 2,

                weChatContent:'',

                dateValue:'',

                options: regionData,
                selectedOptions: [],

                describe: ""
            }
        },
        mounted() {
            this.refreshUserInfoData();
        },
        methods: {
            //编辑按钮
            editingMaterials(key) {
                this[key] = true;
                switch (key) {
                    case 'nameEditing':
                        this.nameContent = this.userInfo.nickname;
                        break;
                    case 'sexEditing':
                        this.sex = this.userInfo.gender;
                        break;
                    case 'weChatEditing':
                        this.weChatContent = this.userInfo.weChat;
                        break;
                    case 'dateEditing':
                        this.dateValue = this.userInfo.birthday;
                        break;
                    case 'addressEditing':
                        this.selectedOptions = [];
                        var addressArr = this.userInfo.area.split('/');
                        if(addressArr[0] && TextToCode[addressArr[0]]){
                            this.selectedOptions.push(TextToCode[addressArr[0]].code);
                        }
                        if(addressArr[1] && TextToCode[addressArr[0]][addressArr[1]]){
                            this.selectedOptions.push(TextToCode[addressArr[0]][addressArr[1]].code);
                        }
                        if(addressArr[2] && TextToCode[addressArr[0]][addressArr[1]][addressArr[2]]){
                            this.selectedOptions.push(TextToCode[addressArr[0]][addressArr[1]][addressArr[2]].code);
                        }
                        break;
                    case 'descEditing':
                        this.describe = this.userInfo.bio || '';
                        break;
                }
            },
            //取消按钮
            editingCancelMaterials(key) {
                this[key] = false;
            },

            //更换头像
            onChange(file) {
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                    this.$message.error('上传文件大小不能超过 5MB!');
                    return false
                }

                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(() => {
                    this.option.img = URL.createObjectURL(file.raw);
                    this.dialogVisible = true
                })
            },
            // 点击裁剪，这一步是可以拿到处理后的地址
            finish() {
                var _this = this;
                _this.$refs.cropper.getCropBlob((data) => {
                    _this.blobToBase64(data).then(res => {
                        // 转化后的base64图片地址
                        _this.$http.post("/api/user/avatar", {
                            avatar: res
                        }).then(() => {
                            _this.refreshUserInfoData(()=>{
                                _this.dialogVisible = false;
                            });
                        })
                    })
                })
            },

            //保存按钮（名字）
            saveBtnName() {
                var _this = this;
                if (!_this.nameContent) {
                    _this.$message({
                        message: '清输入名字',
                        type: 'warning'
                    });
                    return
                }
                _this.$http.get("/api/user/profile", {
                    params: {
                        nickname: _this.nameContent
                    }
                }).then(() => {
                    _this.refreshUserInfoData(()=>{
                        _this.nameEditing = false;
                    });
                })
            },

            //选择性别
            selectSex(sex) {
                this.sex = sex;
            },
            //保存按钮（性别）
            saveBtnSex(){
                var _this = this;
                _this.$http.get("/api/user/profile", {
                    params: {
                        gender: _this.sex
                    }
                }).then(() => {
                    _this.refreshUserInfoData(()=>{
                        _this.sexEditing = false;
                    });
                })
            },

            //保存按钮（微信号）
            saveBtnWeChat() {
                var _this = this;
                _this.$http.get("/api/user/profile", {
                    params: {
                        weChat: _this.weChatContent
                    }
                }).then(() => {
                    _this.refreshUserInfoData(()=>{
                        _this.weChatEditing = false;
                    });
                })
            },

            //保存按钮（生日）
            saveBtnDate() {
                var _this = this;
                _this.$http.get("/api/user/profile", {
                    params: {
                        birthday: _this.dateValue
                    }
                }).then(() => {
                    _this.refreshUserInfoData(()=>{
                        _this.dateEditing = false;
                    });
                })
            },

            //修改地区
            handleChange() {
                //console.log(this.selectedOptions);
                //console.log(CodeToText);
            },
            //保存按钮（地区）
            saveBtnAddress() {
                var arr = [];
                for (let i = 0; i < this.selectedOptions.length; i++) {
                    arr.push(CodeToText[this.selectedOptions[i]]);
                }
                var _this = this;
                _this.$http.get("/api/user/profile", {
                    params: {
                        area: arr.join('/')
                    }
                }).then(() => {
                    _this.refreshUserInfoData(()=>{
                        _this.addressEditing = false;
                    });
                })
            },

            //保存按钮（简介）
            saveBtnDesc() {
                var _this = this;
                _this.$http.get("/api/user/profile", {
                    params: {
                        bio: _this.describe
                    }
                }).then(() => {
                    _this.refreshUserInfoData(()=>{
                        _this.descEditing = false;
                    });
                })
            },

            //刷新个人信息
            refreshUserInfoData(fun) {
                this.$http.get("/api/user/getUser", {}).then((res) => {
                    this.userInfo = res.data;
                    localStorage.setItem("userinfo",JSON.stringify(this.userInfo));
                    window["refreshHeaderFun"]();
                    if(fun){
                        fun(res.data);
                    }
                })
            },


            blobToBase64(blob) {
                return new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.onload = (e) => {
                        resolve(e.target.result);
                    };
                    // readAsDataURL
                    fileReader.readAsDataURL(blob);
                    fileReader.onerror = () => {
                        reject(new Error('文件流异常'));
                    };
                });
            },

        }
    }
</script>

<style>
    .cropper-content .cropper{
        width: auto;
        height: 400px;
    }
</style>