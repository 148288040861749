<template>
    <!-- 我的_编辑资料 -->
    <div id="editingMaterials">
        <div class="edit">
            <div class="edit-word">编辑资料</div>
            <div class="edit-head">
                <div class="edit-head-camera"></div>
            </div>
            <p class=edit-click>点击更换头像</p>
            <div class="content">
                <div class="name">
                    <div class="name-left">名字</div>
                    <div class="name-right">
                        <input type="text" class="test" placeholder="在画用户2001">
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">性别</div>
                    <div class="name-right">
                        <img src="../img/pen.png" alt="" class="name-pen">
                        <input type="text" class="test" placeholder="点击填写">
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">微信号</div>
                    <div class="name-right">
                        <img src="../img/pen.png" alt="" class="name-pen">
                        <input type="text" class="test" placeholder="点击填写">
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">生日</div>
                    <div class="name-right">
                        <img src="../img/pen.png" alt="" class="name-pen">
                        <input type="text" class="test" placeholder="点击填写">
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">地区</div>
                    <div class="name-right">
                        <img src="../img/pen.png" alt="" class="name-pen">
                        <input type="text" class="test" placeholder="点击填写">
                    </div>
                </div>
                <div class="name">
                    <div class="name-left">简介</div>
                    <div class="name-right">
                        <img src="../img/pen.png" alt="" class="name-pen">
                        <input type="text" class="test" placeholder="点击填写">
                    </div>
                </div>
            </div>
            
        </div>
    </div> 
    
   
    
    

</template>

<script>
export default {
    data(){
        return{
            list:[
                {text:"关于我们"},
                {text:"服务使用协议"},
                {text:"隐私协议"},
                {text:"更新日志"},
                {text:"意见反馈"}
            ],
            current:0,

            orderScreen:[
                {title:"充值"},
                {title:"收入"},
                {title:"消费"},
            ],
            orderScreenIndex:0
        }
    },
    methods:{
        listClick(index){
            this.current=index
        },
        // 订单筛选
        orderScreenClick:function(index){
            this.orderScreenIndex=index
        }
    }
}
</script>

<style>

</style>