<template>
    <!-- 搜索-综合 -->
    <div id="searchSynthesis" v-loading="loading">
        <div class="section">
            <div class="section-tip pcClass">
                <div>图集</div>
                <div class="section-tip-select" @click="getWholeAtlas">
                    查看全部 
                    <div class="section-tip-img"></div>
                </div>
            </div>
        </div>
        <!-- 图集组件 -->
        <myAtlas page="index" :myAtlasList="atlasData"></myAtlas>
        <div class="section-user-tip pcClass" v-show="atlasData.length===0">
            — 抱歉,没有找到相关内容 —
        </div>
        <div class="section">
            <div class="section-tip pcClass">
                <div>用户</div>
                <div class="section-tip-select" @click="getWholeUsers">
                    查看全部 
                    <div class="section-tip-img"></div>
                </div>
            </div>
        </div>
        <!-- 用户组件 -->
        <users :userList="usersData"></users>
    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:false,
            atlasData:[],
            usersData:[]
        }
    },
    watch:{
        "$store.state.keyWords"(newval) {
            console.log(newval);
            this.getComprehensiveSearchData()
        },
    },
    mounted(){
        this.getComprehensiveSearchData()
    },
    methods:{
        // 获取图集和用户
        getComprehensiveSearchData(){
            this.loading=true;
            this.$http.get("/api/painting/common_search",{
                params:{
                    keywords:this.$store.state.keyWords
                }
            }).then((res)=>{
                this.loading=false;
                this.atlasData=res.data.painting;
                this.usersData=res.data.user;
                console.log(this.usersData,"yonghu")
            })
        },
        // 获取全部图集
        getWholeAtlas(){
            this.$router.push({path:"/search/searchAtlas"})
        },
        // 获取全部用户
        getWholeUsers(){
            this.$router.push({path:"/search/searchUsers"})
        }
    }
}
</script>

<style>

</style>