<template>
    <div id="privacyAgreement">
        <div class="section">
            <div v-html="content"></div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                id:'',
                content:'',
            }
        },
        mounted(){
            this.id=this.$route.params.id;
            this.getData()
        },
        methods:{
            getData(){
                this.$http.get("/api/index/get_banner_id",{
                    params:{
                        id:this.id
                    }
                }).then((res)=>{
                    this.content=res.data && res.data.content || '';
                });
            }
        }
    }
</script>

<style>

</style>