<template>
    <!-- 删除组件 -->
    <div class="mask deleteAtlas" v-show="show">
        <div class="mask-section deleteAtlas-section">
            <div class="title">删除图集</div>
            <div class="close" @click="cancelDelete"></div>
            <div class="content">
                <div class="content-tip">
                    删除图集不可恢复，确认 是否删除？
                </div>
            </div>
            <div class="button">
                <div class="cancel" @click="cancelDelete">取消</div>
                <div class="confirm" @click="confirmDelete">确定删除</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"deleteAtlas",
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{

        }
    },
    methods:{
        cancelDelete(){
            this.$emit('onCancelDelete');
        },
        confirmDelete(){
            this.$emit('onConfirmDelete');
        }
    }
}
</script>

<style>

</style>