<template>
   <!-- 我的_订单-版本1 -->
    <div id="order">
        <div class="edit">
            <div class="edit-word">订单</div>
            <div class="navbar">
                <div class="navbar-screen" 
                    v-for="(item,index) in orderScreen" :key="index"
                    @click="orderScreenClick(index)"
                    :class="orderScreenIndex===index?'active':''">
                    {{item.title}}
                </div>
            </div>
            <div class="part" v-loading="loading">
                <div class="upper" v-if="orderScreenIndex===0">
                    <div class="firstBox">
                        <div class="first">
                            <div>订单编号</div>
                            <div>订单时间</div>
                            <div>订单金额</div>
                            <div>订单状态</div>
                        </div>
                    </div>

                    <div class="list" v-for="(item,index) in orderList" :key="index">
                        <div>{{item.order.order_id}}</div>
                        <div>{{getDate(item.createtime)}}</div>
                        <div class="yew">+{{item.money}}金瓜子</div>
                        <div>充值成功</div>
                    </div>
                    <!-- <div class="list">
                        <div>cz12345678</div>
                        <div>2020.09.15 15:22</div>
                        <div class="yew">+300金瓜子</div>
                        <div>充值成功</div>
                    </div> -->
                </div>
                <div class="centre" v-if="orderScreenIndex===1">
                    <div class="upper">
                        <div class="firstBox">
                            <div class="first">
                                <div>图集名称</div>
                                <div>付款时间</div>
                                <div>付款人</div>
                                <div>收入金额</div>
                            </div>
                        </div>
                        <div class="list" v-for="(item,index) in orderList" :key="index">
                            <div  @click.stop="imageBtn(item.painting.id)">
                                <img :src="$uploadUrl+(item.painting?item.painting.cover:'')+$imageParams" alt="">
                                {{item.painting?item.painting.name:''}}（{{item.painting?item.painting.pic_count:''}}）</div>
                            <div>{{getDate(item.createtime)}}</div>
                            <div class="ora"  @click.stop="userBtn(item.user.id)">
                               <div class="circle">
                                   <img :src="item.user.avatar"/>
                               </div>
                               <span> {{item.user?item.user.nickname:''}}</span>
                            </div>
                            <div class="yew">金瓜子+{{item.money}}</div>
                        </div>
                        <!-- <div class="list">
                            <div>
                                <img src="../img/backdrop.png" alt="">
                                男性头像（25）</div>
                            <div>2020.09.15 15:22</div>
                            <div class="ora">
                                <div class="circle"></div>
                                达芬奇</div>
                            <div class="yew">金瓜子+1</div>
                        </div> -->
                    </div>
                </div>
                <div class="below" v-if="orderScreenIndex===2">
                    <div class="upper">
                        <div class="firstBox">
                            <div class="first">
                                <div>图集名称</div>
                                <div>付款时间</div>
                                <div>消费金额</div>
                            </div>
                        </div>

                        <div class="list" v-for="(item,index) in orderList" :key="index"   @click.stop="imageBtn(item.painting.id)">
                            <div>
                                <img :src="$uploadUrl+(item.painting?item.painting.cover:'')" alt="">
                                {{item.painting?item.painting.name:''}}（{{item.painting?item.painting.pic_count:''}}）</div>
                            <div>{{getDate(item.createtime)}}</div>
                            <div class="yew">金瓜子{{item.money}}</div>
                        </div>
                        <!-- <div class="list">
                            <div>
                                <img src="../img/backdrop.png" alt="">
                                男性头像（25）</div>
                            <div>2020.09.15 15:22</div>
                            <div class="yew">金瓜子+1</div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            orderScreen:[
                {title:"充值"},
                {title:"收入"},
                {title:"消费"},
            ],
            orderScreenIndex:0,
            loading:false,
            orderList:[]
        }
    },
    mounted(){
        let index = this.$route.params.index*1;
        if(index){
            this.orderScreenIndex = index;
        }
        this.getOrderData()
    },
    methods:{
        // 订单筛选
        orderScreenClick:function(index){
            this.orderScreenIndex=index;
            this.getOrderData()
        },
        // 获取订单
        getOrderData(){
            var myType=null;
            switch(this.orderScreenIndex){
                case 0:myType='recharge';break; //充值
                case 1:myType='gain';break;    //收入
                case 2:myType='spend';break;       //花费
            }
            this.loading=true;
            this.$http.get("/api/user/getUserMoneyLog",{
                params:{
                    type:myType
                }
            }).then((res)=>{
                this.loading=false
                this.orderList=res.data
            })
        },
        getDate(time){
            let date=new Date(time*1000);
            let year=date.getFullYear();
            let month=date.getMonth()+1;
            let day=date.getDate();
            let hour=date.getHours();
            let minute=date.getMinutes();
            return year+"."+month+"."+day+" "+hour+":"+minute
        },
        //图集按钮
        imageBtn(id){
          this.$router.push({path:`/unlockAtlas/${id}`});
        },
        //用户按钮
        userBtn(id){
          this.$router.push({path:`/userPerspectiveOther/${id}`});
        },
    }
}
</script>

<style>

</style>