<template>
    <!-- 各种登录框 -->
    <!-- 注意：1.class为logo的无白色背景图 
        2.地区号下拉框无图，自己编的样式，功能已写
        3.扫一扫提示图没有-->
    <div class="mask loginComponent"  v-show="show" @click="allClick">
        <div class="mask-section">
            <div class="logo">
                <img src="../img/web_logo.png"/>
            </div>
            <div class="close" @click="closeClick"></div>
            <!-- 手机号登录--免密码登录对应 -->
            <div class="phoneLogin" v-if="title=='手机号登录'">
                <div class="content">
                    <div class="content-operation">
                        <div class="content-operation-phone">
                            <div class="area">
                                <div class="area-text">
                                    {{areaNum[current].num}}
                                    <div class="bottom_arrows"></div>
                                </div>
                                <!--<div class="area-text" @click="areaSelectClick">-->
                                    <!--{{areaNum[current].num}}-->
                                    <!--<div class="bottom_arrows"></div>-->
                                <!--</div>-->
                                <!--<div class="area-module" v-show="areaSelect">-->
                                    <!--<div class="list" v-for="(item,index) in areaNum" :key="index"-->
                                        <!--:class="current==index?'active':''"-->
                                        <!--@click="areaNumClick(index)">-->
                                        <!--{{item.num}}-->
                                    <!--</div>-->
                                <!--</div>-->
                            </div>
                            <input type="text" placeholder="请输入手机号码" v-model="codeLogin.phone">
                        </div>
                        <div class="content-operation-code">
                            <input type="text" placeholder="输入4位短信验证码" v-model="codeLogin.code">
                            <div class="getCode" @click="getCode" v-if="!loginTime">获取短信验证码</div>
                            <div class="getCode active" v-if="loginTime">{{loginTime}}s后重新发送</div>
                        </div>
                    </div>
                </div>
                <div class="foot">
                    <div class="button" @click="phoneLogin">
                        注册/登陆
                    </div>
                    <div class="tip">登陆即表示您同意并愿意遵守在画<span class="cursor" @click="xyBtn">用户使用协议</span>和<span class="cursor" @click="ysBtn">隐私协议</span></div>
                    <div class="other">
                        <div class="other-wechat" @click="wechatBtn"></div>
                        <div class="other-qq" @click="qqBtn"></div>
                    </div>
                    <div class="switch" @click="switchPasswordLogin"><span>账号密码登陆</span></div>
                </div>
            </div>
            <!-- 密码登录 -->
            <div class="passwordLogin" v-if="title=='密码登录'">
                <div class="content">
                    <div class="content-operation">
                        <div class="content-operation-phone">
                            <div class="area">
                                <div class="area-text">
                                    {{areaNum[current].num}}
                                    <div class="bottom_arrows"></div>
                                </div>
                                <!--<div class="area-text" @click="areaSelectClick">-->
                                    <!--{{areaNum[current].num}}-->
                                    <!--<div class="bottom_arrows"></div>-->
                                <!--</div>-->
                                <!--<div class="area-module" v-show="areaSelect">-->
                                    <!--<div class="list" v-for="(item,index) in areaNum" :key="index"-->
                                        <!--:class="current==index?'active':''"-->
                                        <!--@click="areaNumClick(index)">-->
                                        <!--{{item.num}}-->
                                    <!--</div>-->
                                <!--</div>-->
                            </div>
                            <input type="text" placeholder="请输入手机号码" v-model="passwordLogin.account">
                        </div>
                        <div class="content-operation-input">
                            <div class="title">密码</div>
                            <input type="password" placeholder="请输入新密码" v-model="passwordLogin.password">
                        </div>
                        <div class="content-operation-forget">
                            <span  @click.stop="forgetSelectClick">忘记密码？</span>
                            <div class="forgetModal" v-show="forgetSelect">
                                <div class="list" @click="forgetBtn">找回密码</div>
                                <div class="list" @click="switchPhoneLogin">短信登录</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="foot">
                    <div class="button" @click="accountPasswordLogin">
                        注册/登陆
                    </div>
                    <div class="tip">登陆即表示您同意并愿意遵守在画<span class="cursor" @click="xyBtn">用户使用协议</span>和<span class="cursor" @click="ysBtn">隐私协议</span></div>
                    <div class="other">
                        <div class="other-wechat"></div>
                        <div class="other-qq"></div>
                    </div>
                    <div class="switch" @click="switchPhoneLogin"><span>手机号免密登陆</span></div>
                </div>
            </div>
            <!-- 扫码登录 -->
            <div class="scanCodeLogin" v-if="title=='扫码登录'">
                <div class="content">
                    <div class="content-step"></div>
                    <div class="content-qrCode">
                        <div class="content-qrCode-tip">打开<span>在画速写手机APP</span></div>
                        <div class="content-qrCode-tip">扫码二维码登陆</div>
                        <img src="https://bkimg.cdn.bcebos.com/pic/2934349b033b5bb571dc8c5133d3d539b600bc12?x-bce-process=image/resize,m_lfit,w_268,limit_1/format,f_jpg" alt="">
                    </div>
                </div>
                <div class="foot">
                    <div class="tip">登陆即表示您同意并愿意遵守在画<span class="cursor" @click="xyBtn">用户使用协议</span>和<span class="cursor"  @click="ysBtn">隐私协议</span></div>
                    <div class="other">
                        <div class="other-wechat"></div>
                        <div class="other-qq"></div>
                    </div>
                    <div class="switch">
                        <span>手机扫码登陆</span>
                        <span>账号密码登陆</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"loginComponent",
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            title:"手机号登录",
            areaNum:[
                {num:"+86"},
                {num:"+61"},
                {num:"+85"}
            ],
            areaSelect:false,
            current:0,
            codeLogin:{
                phone:"",
                code:""
            },
            loginTime:0,
            passwordLogin:{
                account:"",
                password:""
            },
            myBlackTip:false,

            forgetSelect:false,
        }
    },
    methods:{
        //点击其他位置
        allClick(){
            this.forgetSelect = false; //关闭忘记密码弹出层
        },
        // 关闭
        closeClick(){
            this.$emit('onCloseClick');
        },
        // 手机号登录-获取验证码
        getCode(){
            var _this = this;
            let num=this.areaNum[this.current].num.substr(1);
            this.$http.get("/api/sms/send",{
                params:{
                    code:num,
                    mobile:this.codeLogin.phone,
                    event:'mobilelogin'
                }
            }).then((res)=>{
                if(res.code === 0){
                    _this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }else{
                    _this.loginTime = 59;
                    _this.timeFun();
                }
            })
        },
        // 手机号登录
        phoneLogin(){
            this.$http.get("/api/user/mobilelogin",{
                params:{
                    mobile:this.codeLogin.phone,
                    captcha:this.codeLogin.code
                }
            }).then((res)=>{
                this.storeLoginInformation(res)
            })
        },
        // 切换账号密码登录
        switchPasswordLogin(){
            this.title="密码登录"
        },
        // 忘记密码按钮
        forgetSelectClick(){
            this.forgetSelect = true;
        },
        //找回密码按钮
        forgetBtn(){
            this.$emit('onForgetClick');
        },
        // 切换手机号登录
        switchPhoneLogin(){
            this.title="手机号登录";
        },
        // 账号密码登录
        accountPasswordLogin(){
            this.$http.get("/api/user/login",{
                params:{
                    account:this.passwordLogin.account,
                    password:this.passwordLogin.password
                }
            }).then((res)=>{
                // console.log(res);
                if(res.data){
                    this.storeLoginInformation(res)
                }else{
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }
            })
        },
        // 存储登录信息
        storeLoginInformation(res){
            if(res.data&&res.data.userinfo.token){
                localStorage.setItem("token",res.data.userinfo.token);
                localStorage.setItem("userinfo",JSON.stringify(res.data.userinfo));
                this.$emit("onCloseClick");
                this.$message({
                    message: '登录成功',
                    type: 'warning'
                });
                location.reload();
            }else{
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },
        areaSelectClick(){
            this.areaSelect=true
        },
        areaNumClick(index){
            this.current=index;
            this.areaSelect=false
        },

        timeFun:function(){
            let _this = this;
            let goFun = function(){
                let time = _this.loginTime--;
                if(time>0){
                    setTimeout(function(){
                        goFun();
                    },1000);
                }else{
                    _this.loginTime = 0;
                }
            };
            goFun();
        },

        //微信登录
        wechatBtn:function(){
            // var url = 'art.zaihua.art';
            // var url = 'http://www.zaihua.art';
            var url = location.href;
            window.open('https://open.weixin.qq.com/connect/qrconnect?appid=wx497af1480e8b8bde&redirect_uri='+ encodeURIComponent(url) +'&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect');
        },
        //QQ登录
        qqBtn:function(){
            // var url = 'art.zaihua.art';
            // var url = 'http://www.zaihua.art';
            var nowUrl = location.href;
            localStorage.setItem('qqLoginUrl',nowUrl);
            var url = location.protocol + '//www.zaihua.art/qqLogin';
            // window.open('https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=102000865');
            let opt = {
                url:url
            };
            this.$http.get("/api/user/getQQAuthUrl",{
                params:opt
            }).then((res)=>{
                // console.log(res);
                //window.open('https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=1106170653&redirect_uri='+ encodeURIComponent(url) +'&state=123&scope=get_user_info');
                window.open(res.data.url);
            });
        },

        //协议按钮
        xyBtn(){
            this.closeClick();
            this.$router.push({path:`/concern/serviceUsageAgreement`});
        },
        //隐私按钮
        ysBtn(){
            this.closeClick();
            this.$router.push({path:`/concern/privacyAgreement`});
        },
    }
}
</script>

<style>

</style>