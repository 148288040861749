<template>
    <!-- 关注解锁图集组件 -->
    <div class="myAside">
        <div class="button" @click="buttonClick">{{title}}</div>
    </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default:"关注用户并支付2个金瓜子解锁该图集"
        }
    },
    methods:{
        buttonClick(){
            this.$emit('onButtonClick');
        }
    }
}
</script>

<style>

</style>