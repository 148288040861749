<template>
    <!-- 举报组件 -->
    <div class="mask report" v-show="show">
        <div class="mask-section report-section">
            <div class="title">举报</div>
            <div class="close" @click="cancel"></div>
            <div class="content">
                <div class="content-select">
                    <div class="content-select-list" 
                        v-for="(item,index) in select" :key="index"
                        @click="optionsSelected(index)">
                        <div class="option" v-if="current!=index"></div>
                        <div class="selected" v-if="current==index"></div>
                        {{item}}
                    </div>
                </div>
                <div class="content-describe">
                    <textarea placeholder="请描述您遇到的问题，我们会尽快解决" 
                        v-model="describe"
                        maxlength="100">
                    </textarea>
                    <div class="content-describe-font">{{describe.length}}/100</div>
                </div>
            </div>
            <div class="button">
                <div class="cancel" @click="cancel">取消</div>
                <div class="confirm" @click="confirm">确定</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"report",
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            select:[
                {text:"广告信息、诱导关注分享"},
                {text:"涉黄涉暴等违法信息"},
                {text:"侮辱、恶意及辱骂行为"},
                {text:"侵权、盗版"},
                {text:"其它"}
            ],
            current:-1,
            describe:""
        }
    },
    watch:{
      show(newValue){
        if(newValue){
          this.current = -1;
          this.describe = '';
        }
      }
    },
    mounted(){
        this.getReportTypeData()
    },
    methods:{
        // 类型切换
        optionsSelected(index){
            this.current=index
        },
        // 获取举报类型
        getReportTypeData(){
            this.$http.get("/api/painting/painting_report_type",{
            }).then((res)=>{
                this.select=res.data
            })
        },
        // 取消
        cancel(){
            this.$emit('onCancel');
        },
        // 确定
        confirm(){
            this.$emit('onConfirm',this.select[this.current],this.describe,()=>{
                this.current = -1;
                this.describe = '';
            });
        },
    }
}
</script>

<style>

</style>