<template>
    <!-- 我的关注 -->
    <div id="myConcerns">
        <div class="edit">
            <div class="title">
                <div class="title-list" v-for="(item,index) in selectData" :key="index"
                    :class="index==current?'active':''"
                    @click="selectDataClick(index)">
                    {{item.title}} ({{index === 0?focusList.length : fansList.length}})
                </div>
            </div>
            <!-- 用户组件 关注-->
            <users :userList="focusList" v-if="current === 0" @onSuccess="successFun"></users>
            <!-- 用户组件 粉丝-->
            <users :userList="fansList" v-if="current === 1" @onSuccess="successFun"></users>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            id:'',
            selectData:[
                {title:"关注"},
                {title:"粉丝"}
            ],
            current:0,
            focusList:[],
            fansList:[],
        }
    },
    mounted(){
        this.current=this.$route.params.index*1;
        this.id=this.$route.params.id;
        this.refreshFocusListData();
        this.refreshFansListData();
    },
    methods:{
        selectDataClick(index){
            this.current=index;
        },
        successFun(){
            this.refreshFocusListData();
            this.refreshFansListData();
        },
        refreshFocusListData(){
            if(this.id){
                this.$http.get("/api/user/get_my_follow_user_id",{
                    params:{
                        user_id:this.id
                    }
                }).then((data)=>{
                    this.focusList=data.data;
                })
            }else{
                this.$http.get("/api/user/get_my_follow_user",{}).then((data)=>{
                    this.focusList=data.data;
                })
            }
        },
        refreshFansListData(){
            if(this.id){
                this.$http.get("/api/user/get_follow_user_id",{
                    params:{
                        user_id:this.id
                    }
                }).then((data)=>{
                    this.fansList=data.data;
                })
            }else{
                this.$http.get("/api/user/get_follow_user",{}).then((data)=>{
                    this.fansList=data.data;
                })
            }
        }
    }
}
</script>

<style>

</style>