<template>
    <!-- 我的-一期-用户视角 -->
    <div id="userPerspective" v-loading="fullscreenLoading">
        <div class="section">
            <div class="section-info pcClass">
                <div class="section-info-left">
                    <img :src="userinfo.avatar" alt="">
                    <div class="info">
                        <div class="info-name">{{userinfo.nickname}}</div>
                        <div class="info-id">ID：{{userinfo.id}}</div>
                        <div class="info-parm">
                            <span class="hover" @click="likeBtn">关注（{{userinfo.like_count}}）</span>
                            <span class="hover" @click="beLikeBtn">粉丝（{{userinfo.be_like_count}}）</span>
                            <span>金瓜子（{{userinfo.money*1}}）</span>
                        </div>
                    </div>
                </div>
                <div class="section-info-right">
                    <div v-if="userinfo.is_follow" @click="focusUerBtn(userinfo.id)"><div class="button follow active">已关注</div></div>
                    <div v-if="!userinfo.is_follow" @click="focusUerBtn(userinfo.id)"><div class="button follow">关注</div></div>
                    <div><div class="button edit" @click="editInfoBtn" style="opacity: 0">编辑资料</div></div>
                </div>
            </div>
            <div class="section-select pcClass">
                <div class="section-select-text" 
                    v-for="(item,index) in select" :key="index"
                    :class="index==current?'active':''"
                    @click="selectClick(index)">
                    {{item.title}}({{item.num}})
                </div>
            </div>
            <div class="section-collection pcClass" v-if="current">
                <span class="section-collection-text" 
                    v-for="(item,index) in collection" :key="index"
                    @click="collectionClick(index)"
                    :class="index==collectionCurrent?'active':''">{{item.text}}（{{item.num}}）</span>
            </div>
        </div>
        <!-- 图集组件 -->
        <myAtlas page="myPainting" :myAtlasList="myAtlasList" v-if="current===0"></myAtlas>
        <!-- 收藏图集 -->
        <myAtlas page="myPainting" :myAtlasList="collectionList.collectionAtlas" v-if="current===1&&collectionCurrent===0"></myAtlas>
        <!-- 已购买图集 -->
        <myAtlas page="myPainting" :myAtlasList="collectionList.purchased" v-if="current===1&&collectionCurrent===1"></myAtlas>
        <!-- 最近查看图集 -->
        <myAtlas page="myPainting" :myAtlasList="collectionList.recentlyViewed" v-if="current===1&&collectionCurrent===2"></myAtlas>
        <!-- 支付组件 -->
        <payment ref="payment_my" :title="''" @tipBtnClick="tipBtnClick" @onCompleteFun="onCompleteFun"></payment>
        <!--充值协议-->
        <myTip :show="myTipShow"
               title="充值服务协议"
               @onCloseClick="onTipCloseClick"
               @onButtonClick="onTipCloseClick">
        </myTip>
    </div>
</template>

<script>
export default {
    data(){
        return{
            id:'',
            select:[
                {title:"图集",num:0},
                {title:"收藏图集",num:0}
            ],
            current:0,
            collection:[
                {text:"收藏图集",num:200},
                {text:"已购买",num:200},
                {text:"最近查看",num:20}
            ],
            collectionCurrent:0,
            userinfo:{},
            myAtlasList:[],     //图集列表
            collectionList:{        //收藏
                collectionAtlas:[],     //收藏图集
                purchased:[],       //已购买图集
                recentlyViewed:[]       //最近查看图集
            },       
            fullscreenLoading:true,

            myTipShow:false,
        }
    },
    mounted(){
        this.id = this.$route.params.id;
        this.getData()
    },
    methods:{
        selectClick(index){
            this.current=index
        },
        collectionClick(index){
            this.collectionCurrent=index
        },
        //编辑资料按钮
        editInfoBtn(){
            // this.$router.push({path: "/editingMaterials/"})
            // this.$router.push({path: "/editorialPresentation/"})
            this.$router.push({path: "/fillInInformation/"})
        },
        //关注按钮
        focusUerBtn(id){
            this.$http.get("/api/user/follow_user",{
                params:{
                    user_id:id
                }
            }).then(()=>{
                this.getMyInfo();
            })
        },
        // 获取我的信息
        getMyInfo(){
            return new Promise((resolve)=>{
                this.$http.get("/api/user/get_user_id",{
                    params:{
                        id:this.id
                    }
                }).then((res)=>{
                    resolve(res);
                    this.userinfo=res.data
                })
            })
        },
        // 获取图集
        getAtlas(){
            return new Promise((resolve)=>{
                this.$http.get("/api/painting/get_painting",{
                    params:{
                        user_id:this.id
                    }
                }).then((res)=>{
                    resolve(res);
                    this.myAtlasList=res.data;
                    this.select[0].num=this.myAtlasList.length
                })
            })
        },
        // 获取收藏图集
        getViewCollection(){
            return new Promise((resolve)=>{
                this.$http.get("/api/painting/get_like_painting",{
                    params:{
                        user_id:this.id
                    }
                }).then((res)=>{
                    resolve(res);
                    this.collectionList.collectionAtlas=res.data;
                    this.collection[0].num=this.collectionList.collectionAtlas.length
                })
            })
        },
        // 获取已购买图集
        getPurchased(){
            return new Promise((resolve)=>{
                this.$http.get("/api/painting/get_buy_painting",{
                    params:{
                        user_id:this.id
                    }
                }).then((res)=>{
                    resolve(res);
                    this.collectionList.purchased=res.data;
                    this.collection[1].num=this.collectionList.purchased.length;
                })
            })
        },
        // 获取最近查看图集
        getRecentlyViewed(){
            return new Promise((resolve)=>{
                this.$http.get("/api/painting/get_painting_history",{
                    params:{
                        user_id:this.id
                    }
                }).then((res)=>{
                    resolve(res);
                    this.collectionList.recentlyViewed=res.data;
                    this.collection[2].num=this.collectionList.recentlyViewed.length;
                    console.log(this.collectionList.recentlyViewed,"963")
                })
            })
        },
        // 获取数据
        async getData(){
            await this.getMyInfo();
            await this.getAtlas();
            await this.getViewCollection();
            await this.getPurchased();
            await this.getRecentlyViewed();
            this.select[1].num=this.collectionList.collectionAtlas.length;
            // this.select[1].num=this.collectionList.collectionAtlas.length+
            //                     this.collectionList.purchased.length+
            //                     this.collectionList.recentlyViewed.length;
            this.fullscreenLoading=false
        },
        //关注按钮
        likeBtn(){
            this.$router.push({path: "/myConcerns/0/" + this.userinfo.id})
        },
        //粉丝按钮
        beLikeBtn(){
            this.$router.push({path: "/myConcerns/1/" + this.userinfo.id})
        },
        //金瓜子按钮
        moneyBtn(){
            this.$refs.payment_my.paymentShow = true;
        },
        //充值协议
        tipBtnClick(){
            this.myTipShow = true;
        },
        onTipCloseClick(){
            this.myTipShow = false;
        },
        //充值成功回调
        onCompleteFun(){
            this.$refs.payment_my.paymentShow = false;
            this.getData();
        }
    }
}
</script>

<style>

</style>