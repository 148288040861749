<script src="../../babel.config.js"></script>
<template>
    <!-- 功能异常 -->
    <div class="mask dysfunction">
        <div class="mask-section">
            <div class="title">{{title}}</div>
            <div class="close" @click="closeClick"></div>
            <div class="content">
                <div class="content-contact">
                    <div>联系方式</div>
                    <input type="text" placeholder="邮箱/手机号" v-model="contact">
                </div>
                <div class="content-describe">
                    <textarea placeholder="请填写10个字以上的问题描述以便我们提供更好的帮助"
                              v-model="describe"
                              maxlength="100">
                    </textarea>
                    <div class="content-describe-font">{{describe.length}}/100</div>
                </div>
                <div class="content-img">
                    <el-upload
                            :headers="headersObj"
                            action="http://admin.zaihua.art/api/common/upload"
                            multiple
                            list-type="picture-card"
                            :on-remove="handleRemove"
                            :on-success="onSuccess"
                            accept="image/*"
                            ref="fileupload"
                    >
                        <i class="el-icon-plus"></i>
                        <div class="uploadClassTip">添加截图</div>
                    </el-upload>
                    <!-- <div class="content-img-tip">添加截图</div> -->
                </div>
            </div>
            <div class="button">
                <div class="cancel" @click="cancel">取消</div>
                <div class="confirm" @click="confirm">确定</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "authentication",
        props: {
            title: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                headersObj:{
                    token:''
                },
                describe: "",
                contact: "",     //联系方式
                myAtlasList: [], //图片列表
            }
        },
        mounted(){
            this.headersObj = {
                token:localStorage.getItem("token")
            };
        },
        methods: {
            closeClick() {
                this.$emit('onCloseClick');
            },
            cancel() {
                this.$emit('onCloseClick');
            },
            confirm() {
                var opt = {};
                var _this = this;
                if(!_this.contact){
                    this.$message({
                        message: '请输入联系方式',
                        type: 'warning'
                    });
                    return
                }
                if(!_this.describe){
                    this.$message({
                        message: '请输入问题描述',
                        type: 'warning'
                    });
                    return
                }
                if(_this.title !== '支付问题' && !_this.myAtlasList.length){
                    this.$message({
                        message: '请添加截图',
                        type: 'warning'
                    });
                    return
                }
                opt.content = _this.describe;
                opt.contact = _this.contact;
                var imageArr = [];
                for(var i = 0;i<_this.myAtlasList.length;i++){
                    imageArr.push(_this.myAtlasList[i].cover);
                }
                opt.pic = imageArr.join(',');
                switch (_this.title) {
                    case '功能异常':
                        opt.type = 'gnyc';
                        break;
                    case '支付问题':
                        opt.type = 'zfwt';
                        break;
                    case '产品建议':
                        opt.type = 'cpjy';
                        break;
                }
                _this.refreshSaveData(opt,()=>{
                    _this.contact = '';
                    _this.describe = '';
                    _this.myAtlasList = [];
                    _this.$refs['fileupload'].clearFiles();
                    _this.$emit('onConfirm');
                });
            },
            handleRemove(file, fileList) {
                for (let i = 0; i < fileList.length; i++) {
                    if (fileList[i].status === 'success') {
                        this.myAtlasList.push({
                            cover: fileList[i].response.data.url,
                            is_buy: true,
                            id: fileList[i].response.data.url,
                        })
                    }
                }
            },

            onSuccess(event, file, fileList) {
                this.myAtlasList = [];
                for (let i = 0; i < fileList.length; i++) {
                    if (fileList[i].status === 'success') {
                        this.myAtlasList.push({
                            cover: fileList[i].response.data.url,
                            is_buy: true,
                            id: fileList[i].response.data.url,
                        })
                    }
                }
            },

            refreshSaveData(options,fun){
                this.$http.get("/api/setting/add_suggestion",{params:options}).then(()=>{
                    if(fun){
                        fun();
                    }
                })
            },
        }
    }
</script>

<style>

</style>