<template>
    <!-- 用户组件 -->
    <div class="users">
        <div class="section-user pcClass">
            <div class="section-user-list" v-for="(item,index) in userList" :key="index">
                <div class="user-left">
                    <img :src="item.avatar" alt="">
                    <div class="user-left-info">
                        <div class="info-title">{{item.nickname}}</div>
                        <div class="info-text">{{item.bio}}</div>
                    </div>
                </div>
                <div class="user-right">
                    <div class="user-follow-true" v-if="userInfo.id !== item.id && !item.f_is_follow && item.is_follow" @click="followClick(index,item.id)">
                        已关注
                    </div>
                    <div class="user-follow-true" v-if="userInfo.id !== item.id && item.f_is_follow && item.is_follow" @click="followClick(index,item.id)">
                        互相关注
                    </div>
                    <div class="user-follow-false" v-if="userInfo.id !== item.id && !item.is_follow" @click="followClick(index,item.id)">
                        +关注
                    </div>

                    <!--<view class="focusBtn" v-if="userInfo.id !== item.id && !item.f_is_follow && item.is_follow" @tap="focusBtn(item.id)">-->
                        <!--已关注-->
                    <!--</view>-->
                    <!--<view class="focusBtn" v-if="userInfo.id !== item.id && item.f_is_follow && item.is_follow" @tap="focusBtn(item.id)">-->
                        <!--互相关注-->
                    <!--</view>-->
                    <!--<view class="focusBtn active" v-if="userInfo.id !== item.id && !item.is_follow" @tap="focusBtn(item.id)">-->
                        <!--+关注-->
                    <!--</view>-->
                </div>
            </div>
            <div class="section-user-tip pcClass" v-if="userList.length==0">
                — 抱歉,没有找到相关内容 —
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"users",
    props:{
        type:{
            type:String,
            default:'my',
        },
        userList:{
            type:Array,
        }
    },
    data(){
        return{
            // userList:[
            //     {
            //         head:"https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1606844928621&di=921e2fae4c594388fd2a980794e8ef0b&imgtype=0&src=http%3A%2F%2Fa4.att.hudong.com%2F27%2F67%2F01300000921826141299672233506.jpg",
            //         title:"哈士奇",
            //         text:"我是一个快乐的逗比哈士奇,善良又可爱",
            //         follow:true
            //     },
            //     {
            //         head:"https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1606844928621&di=921e2fae4c594388fd2a980794e8ef0b&imgtype=0&src=http%3A%2F%2Fa4.att.hudong.com%2F27%2F67%2F01300000921826141299672233506.jpg",
            //         title:"哈士奇",
            //         text:"我是一个快乐的逗比哈士奇",
            //         follow:false
            //     }
            // ]
        }
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem("userinfo") || '{}');
    },
    methods:{
        // 关注
        followClick(index,id){
            if(!this.getToken()){
                return
            }
            this.userList[index].is_follow=!this.userList[index].is_follow;
            this.$http.get("/api/user/follow_user",{
                params:{
                    user_id:id
                }
            }).then(()=>{
                this.$emit('onSuccess');
            })
        },
    }
}
</script>

<style>

</style>