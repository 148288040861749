<template>
<!-- 我的_设置 -->
  <div id="settings">
        <div class="edit">
            <div class="edit-word">设置</div>
            <div class="content">
               <div class="name">
                    <div class="name-left">更换手机号</div>
                    <div class="name-right">
                        <span class="test">{{mobileFun(userinfo.mobile)}}</span>
                        <div class="move" @click="changeMobilePhoneNumber('updatePhone')">
                            <img src="../img/color-pen.png" alt="">
                            <span class="fl">编辑</span>
                        </div>
                    </div>
                </div> 
                <div class="name">
                    <div class="name-left">修改密码</div>
                    <div class="name-right">
                        <span class="test">*****</span>
                        <div class="move" @click="changeMobilePhoneNumber('updatePassword')">
                            <img src="../img/color-pen.png" alt="">
                            <span class="fl">编辑</span>
                        </div>
                    </div>
                </div> 
                 <div class="name">
                    <div class="name-left">微信登录</div>
                    <div class="name-right">
                        <span class="test pointer" @click="changeMobilePhoneNumber">解除绑定</span>
                    </div>
                </div> 
                 <div class="name">
                    <div class="name-left">QQ登录</div>
                    <div class="name-right">
                        <span class="test pointer">绑定QQ</span>
                    </div>
                </div>
            </div>
        </div>
        <authentication 
            v-show="authenticationShow" 
            title="身份验证" 
            @onCloseClick="authenticationShow=false"
            @onButtonClick="onButtonClick">
        </authentication>
  </div>
</template>

<script>
export default {
    data(){
        return{
            userinfo:{},
            authenticationShow:false,
            update:""
        }
    },
    mounted() {
        this.userinfo = JSON.parse(localStorage.getItem("userinfo") || '{}');
    },
    methods:{
        // 打开身份验证弹窗
        changeMobilePhoneNumber(updateText){
            this.update=updateText;
            this.authenticationShow=true
        },
        // 验证
        onButtonClick(userinfo,code){
            this.$http.get("/api/sms/check",{
                params:{
                    mobile:userinfo.mobile,
                    event:'changemobile1',
                    captcha:code
                }
            }).then((res)=>{
                if (res.code != 1 && res.msg) {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                    return
                }
                this.$router.push({path:`/modifySettings/${this.update}`})
            })
        },
        mobileFun(input){
            if (input !== undefined) {
                input =
                    input.substring(0, 3) + " **** " + input.substring(input.length - 4);
            }
            return input;
        }
    }
}
</script>

<style>

</style>