<template>
  <!-- 设置_关于我们_意见反馈-版本1 -->
    <div id="feedback">
        <div class="content">
            <div class="name">
                <div class="name-left" @click="feedback('功能异常')">功能异常</div>
                </div>
            <div class="name">
                <div class="name-left" @click="feedback('支付问题')">支付问题</div>
            </div>
            <div class="name">
                <div class="name-left" @click="feedback('产品建议')">产品建议</div>
            </div>
        </div>
        <dysfunction 
            v-show="dysfunctionShow"
            :title="title"
            @onConfirm="onConfirm"
            @onCloseClick="closeBtn">
        </dysfunction>
    </div>
</template>

<script>
export default {
    data(){
        return{
            dysfunctionShow:false,
            title:""
        }
    },
    mounted(){
        if(this.$route.params.type === 'pay'){
            this.feedback('功能异常');
        }
    },
    methods:{
        // 反馈
        feedback(value){
            if(!this.getToken()){
                return
            }
            this.title=value;
            this.dysfunctionShow=true;
        },
        //反馈关闭按钮
        closeBtn(){
            this.dysfunctionShow=false;
        },
        //确认按钮
        onConfirm(){
            this.closeBtn();
            this.$message({
                message: '提交成功',
                type: 'success'
            });
        }
    }
}
</script>

<style>

</style>