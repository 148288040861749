import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userinfo:null,
    keyWords:"",
    isLogin:false,
  },
  mutations: {
      setIsLogin(state,value){
        state.isLogin = value;
      }
  },
  actions: {

  },
  modules: {

  }
});
