<template>
  <!-- 设置_关于我们-版本1 -->
  <!-- 注意：没有隐私协议页面 -->
    <div id="concern">
        <div class="edit">
            <div class="test">
                <router-link v-for="(item,index) in list" :key="index" @click="listClick(index)"
                    :to="item.path" 
                    tag="div" exact>{{item.text}}</router-link>
                <!-- <div v-for="(item,index) in list" :key="index" @click="listClick(index)"
                    :class="index==current?'active':''">{{item.text}}</div> -->
            </div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            list:[
                {text:"关于我们",path:"/concern/"},
                {text:"服务使用协议",path:"/concern/serviceUsageAgreement"},
                {text:"隐私协议",path:"/concern/privacyAgreement"},
                {text:"更新日志",path:"/concern/updatelog"},
                {text:"意见反馈",path:"/concern/feedback"}
            ],
            current:0,
        }
    },
    methods:{
        listClick(index){
            this.current=index;
        }
    }
}
</script>

<style>
</style>