// axios
import axios from 'axios'
export const uploadUrl = 'http://image.zaihua.art';     //图片url
export const shareUrl = 'http://h5.zaihua.art/#/pages/pcShare_qq/pcShare_qq?id=';     //图片url
export const imageParams = '?imageView2/1/w/176/h/176/q/50/ignore-error/1/';     //小图七牛云压缩
export const imageParams_big = '?imageslim';     //小图七牛云压缩
let baseURL = 'http://admin.zaihua.art';
if(window.location.hostname !== 'localhost'){
    baseURL = window.location.origin;
}
export const http=axios.create({
    timeout:80000,
    baseURL:baseURL
});


// 响应拦截器
http.interceptors.response.use(
    (res) => {
      return res.data || {};
    },
    // (err) => {
    //   // status
    //   // 通用的
    //   if (err.response.status === 401) {
    //     // 调整到 login 页面
    //     router.replace({
    //       name: "Login",
    //     });
    //   }
    // }
    error => {
        if (error.response.status === 401) {
            localStorage.setItem("token",'');
            localStorage.setItem("userinfo",'');
            // location.href = location.origin;
        }else{
            return Promise.reject(error);
        }
    }
);

// 请求拦截器
// token
http.interceptors.request.use((config) => {
    // 先获取 token
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.token = token;
    }
    return config;
});
  
