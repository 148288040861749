<template>
    <!-- 图集列表组件 -->
    <div class="myAtlas">
        <div class="content pcClass">
            <el-row :gutter="10">
                <el-col :xs="8" :sm="6" :md="4" :lg="4" :xl="3" v-for="(item,index) in myAtlasList" :key="'myAtlas'+index + (item.id||'')">
                    <div class="dataList"
                         :class="(item.is_buy || (item.painting && item.painting.is_buy))?'':'active'"
                         @click="atlasListClick((item.painting && item.painting.id) || item.id , index,item.is_buy,)">
                        <!--<img :src="$uploadUrl+(item.cover || (item.painting && item.painting.cover))+$imageParams" alt="" v-if="item.cover || (item.painting && item.painting.cover)">-->
                        <el-image :class="(item.coverUrl && item.status && item.status !== 'success')?'imageBox':''" :src="(item.coverUrl && item.status && item.status !== 'success')?item.coverUrl:($uploadUrl+(item.cover || (item.painting && item.painting.cover))+$imageParams)" alt="" v-if="item.cover || (item.painting && item.painting.cover)" fit="cover">
                            <div slot="error" class="image-slot"></div>
                        </el-image>
                        <img src="../img/add.png" alt=""  v-if="page==='add' && item.status && item.status !== 'success'" style="opacity: 0;">
                        <!-- 加active代表已解锁图集 -->
                        <!--<div class="dataList-lock" @click.stop="unlockAtlas(item.is_buy,item.id)"></div>-->
                        <!-- 精选和VIP小标 -->
                        <div class="selectedVip">
                            <div v-if="page==='index'">
                                <div class="selectedVip-title vip" v-if="item.channel === 'admin' && item.is_vip === 1">VIP</div>
                                <div class="selectedVip-title selected" v-if="item.channel === 'user' && item.status === 'accept' && item.is_jp">精选</div>
                                <div class="selectedVip-title free" v-if="item.channel === 'admin' && item.is_vip === 0">免费</div>
                            </div>
                            <div v-if="page==='myPainting'">
                                <div class="selectedVip-title selected" v-if="item.status && item.status==='accept'&&item.is_jp===1">精选</div>
                                <div class="selectedVip-title examine" v-if="item.status && item.status!=='accept'">审核中</div>
                                <div class="selectedVip-title vip" v-if="item.painting && item.painting.channel === 'admin' && item.painting.is_vip === 1">VIP</div>
                                <div class="selectedVip-title selected" v-if="item.painting && item.painting.channel === 'user' && item.painting.status === 'accept' && item.painting.is_jp">精选</div>
                            </div>
                            <div class="selectedVip-title selected" v-if="page==='add' && cover === index">封面</div>
                            <div class="selectedVip-num" v-show="item.pic_count">
                                {{item.pic_count}}
                                <div class="selectedVip-num-circle" v-show="item.is_red"></div>
                            </div>
                        </div>
                        <div class="selectedVip-set" v-if="page==='add'">
                            <div class="set-icon"><div></div></div>
                            <div class="set-module">
                                <div class="set-type" :class="item.title === '删除'? 'delete':''" v-for="(item,num) in set" :key="num" @click="setClick(index,num)">
                                    {{item.title}}
                                    <div class="set-type-active" v-if="num === 0 && cover===index"></div>
                                </div>
                            </div>
                        </div>
                        <div class="selectedVipLoadingBox" v-if="page==='add' && item.status && item.status !== 'success'">
                            <div class="selectedVipLoading">
                                <zCircle :value="item.percentage"></zCircle>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <!--<div class="dataList" v-for="(item,index) in seize" :key="index"></div>-->
        </div>
        <!-- 解锁图集组件 -->
        <myTip :show="myTipShow" 
            title="解锁图集" 
            text="关注用户并支付2个金瓜子即可解锁该图集"
            @onCloseClick="onCloseClick"
            @onButtonClick="onButtonClick">
        </myTip>
        <!-- 支付组件 -->
        <payment ref="payment"></payment>
        <!-- 提示 -->
        <myBlackTip :text="myBlackTip.successTitle" v-show="myBlackTip.show"></myBlackTip>
    </div>
</template>

<script>
    import store from "../store/index.js";
export default {
    name:"myAtlas",
    props:{
        myAtlasList:{
            type:Array,
        },
        page:{
            type:String,
            default:""
        },
        cover:{
            type:Number,
            default:0
        }
    },
    data(){ 
        return{
            list:[
                // {
                //     img:"https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1606665735904&di=77c868013179d38992b6786a510d6c53&imgtype=0&src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201912%2F08%2F20191208160837_qvcbl.thumb.700_0.jpg",
                //     setCurrent:0
                // },
                // {
                //     img:"https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1606665735904&di=77c868013179d38992b6786a510d6c53&imgtype=0&src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201912%2F08%2F20191208160837_qvcbl.thumb.700_0.jpg",
                //     setCurrent:0
                // }
            ],
            seize:[],
            myTipShow:false,
            set:[
                {title:"设为封面"},
                {title:"删除"},
            ],
            myBlackTip:{
                successTitle:"举报成功",
                show:false
            }
        }
    },
    watch:{
        // 根据图集数量计算补位
        myAtlasList:function(newValue){
            this.seize=[];
            let num=parseInt(document.querySelector(".content").offsetWidth/235);   //计算本屏幕能容纳多少个图集（235为固定宽度）
            if(newValue.length%num!==0){
                let remainder=newValue.length%num;
                let many=num-remainder;
                for(let i=0;i<many;i++){
                    this.seize.push({})
                }
            }
        }
    },
    mounted(){
    },
    methods:{
        // 解锁图集
        unlockAtlas(is_buy,oId){
            if(is_buy){
                this.deleteRed(oId)
            }else{
                this.myTipShow=true
            }
        },
        // 关闭弹窗
        onCloseClick(){
            this.myTipShow=false
        },
        // 关注并支付
        onButtonClick(){
            // 查询我的信息
            this.$http.get("/api/user/getUser",{
            }).then((res)=>{
                if(res.data.money*1 >= 2){     //有钱的话直接支付购买图集
                    this.$http.get("/api/painting/buy_painting",{
                        params:{
                            painting_id:this.$route.params.id
                        }
                    }).then((res)=>{
                        this.myTipShow=false;
                        this.myBlackTip.show=true;
                        this.myBlackTip.successTitle=res.msg;
                        setTimeout(()=>{
                            this.myBlackTip.show=false;
                            location.reload();
                        },2000)
                    })
                }else{                  //没钱的话去充值
                    this.$refs.payment.paymentShow=true
                }
            })
        },
        setClick(index,num){
            if(num === 0){
                this.$emit('onCover',index);
            }else{
                this.$emit('onDelete',index);
            }
        },
        deleteRed(oId,fun){
            this.$http.get("/api/painting/delete_painting_red",{
                params:{
                    id:oId
                }
            }).then(()=>{
                // console.log(res)
                // this.$router.push({path:"/pictureDisplay"})
                if(fun){
                    fun();
                }
            })
        },
        // 点击图集
        atlasListClick(id,index,is_buy){
            switch(this.page){
                case "index":
                    this.$router.push({path:`/unlockAtlas/${id}`});
                    break;
                case "unlockAtlas":
                    if(localStorage.getItem("token")){
                        if(is_buy){
                            this.deleteRed(id,()=>{
                                this.$router.push({path:`/pictureDisplay/${id}/${index+1}`});
                            })
                        }else{
                            this.myTipShow=true
                        }
                    }else{
                        store.commit('setIsLogin',true);
                    }
                    break;
                case "myPainting":
                    this.$router.push({path:`/unlockAtlas/${id}`});
                    break;
                case "pictureDisplay":
                    this.$router.push({path:`/unlockAtlas/${id}`});
                    break;
            }
        },
    }
}
</script>

<style>

</style>