<template>
  <div id="app" style="background-color: #F5F5F5;">
    <myHeader :key="headerKey"></myHeader>
    <router-view :key="$route.fullPath"></router-view>
    <dysfunction
            v-show="dysfunctionShow"
            title="支付问题"
            @onConfirm="onConfirm"
            @onCloseClick="closeBtn">
    </dysfunction>
  </div>
</template>

<script>
import myHeader from "@/components/myHeader";   //引入头部组件
export default {
    data(){
        return {
            headerKey:'',

            dysfunctionShow:false,
        }
    },
    components:{
        myHeader,       //注册头部组件
    },
    mounted() {
        window["refreshHeaderFun"] = () => {
            this.refreshHeaderFun();
        };
        window["dysfunctionShowFun"] = () => {
            this.dysfunctionShowFun();
        };
    },
    methods:{
        refreshHeaderFun(){
            this.headerKey = new Date().getTime();
        },

        dysfunctionShowFun(){
            this.dysfunctionShow =true;
        },
        //反馈关闭按钮
        closeBtn(){
            this.dysfunctionShow=false;
        },
        //确认按钮
        onConfirm(){
            this.closeBtn();
            this.$message({
                message: '提交成功',
                type: 'success'
            });
        },
    }
}
</script>

<style>
  *{
    margin: 0;
    padding: 0;
  }
  html,body,#app{
    height: 100%;
  }
</style>
