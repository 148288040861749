<template>
    <!-- 我的-设置-修改设置 -->
    <div id="modifySettings">
        <div class="edit">
            <div class="edit-word">设置</div>
            <div class="content">
                <div class="name topage" v-if="update!=='updatePhone'">
                    <div class="name-left">更换手机号</div>
                    <div class="name-right">
                        <span class="test">185****0543</span>
                        <div class="move" @click="changeMobilePhoneNumber('updatePhone')">
                            <img src="../img/color-pen.png" alt="">
                            <span class="fl">编辑</span>
                        </div>
                    </div>
                </div> 
                <div class="name" v-if="update==='updatePhone'">
                    <div class="name-left">更换手机号</div>
                    <div class="name-right">
                        <div class="input">
                            <input type="text" class="nameInput" v-model="phone">
                        </div>
                        <div class="button">
                            <div class="getCode" @click="getCode">获取短信验证码</div>
                        </div>
                        <div class="button">
                            <input type="text" class="code" placeholder="请输入4位验证码" v-model="code">
                            <div class="preservation" @click="defineUpdatePhone">确定</div>
                        </div>
                    </div>
                </div>
                <div class="name topage" v-if="update!=='updatePassword'">
                    <div class="name-left">修改密码</div>
                    <div class="name-right">
                        <span class="test">*****</span>
                        <div class="move" @click="changeMobilePhoneNumber('updatePassword')">
                            <img src="../img/color-pen.png" alt="">
                            <span class="fl">编辑</span>
                        </div>
                    </div>
                </div> 
                <div class="name" v-if="update==='updatePassword'">
                    <div class="name-left">修改密码</div>
                    <div class="name-right">
                        <div class="input">
                            设置密码
                            <input type="password" class="nameInput passworld" placeholder="请输入新密码" v-model="passwordOne">
                        </div>
                        <div class="input">
                            确认密码
                            <input type="password" class="nameInput passworld" placeholder="请再次输入新密码" v-model="passwordTwo">
                        </div>
                        <div class="button">
                            <div class="cancel" @click="cancelUpdatePassword">取消</div>
                            <div class="preservation" @click="defineUpdatePassword">确认</div>
                        </div>
                    </div>
                </div>
                <div class="name login">
                    <div class="name-left">微信登陆</div>
                    <div class="name-right">
                        <div class="unbinding">解除绑定</div>
                    </div>
                </div>
                <div class="name login">
                    <div class="name-left">QQ登陆</div>
                    <div class="name-right">
                        <div class="binding">绑定QQ</div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            phone:"",
            update:"",
            code:"",

            passwordOne:"",
            passwordTwo:""
        }
    },
    mounted(){
        this.phone=JSON.parse(localStorage.getItem("userinfo") || '{}').mobile;
        this.update=this.$route.params.update
    },
    methods:{
        // 获取验证码
        getCode(){
            this.$http.get("/api/sms/send",{
                params:{
                    code:"86",
                    mobile:this.phone,
                    event:'changemobile'
                }
            }).then((res)=>{
                console.log(res,3333)
            })
        },
        // 修改密码或更换手机号切换
        changeMobilePhoneNumber(text){
            this.update=text
        },
        // 确定更改手机号
        defineUpdatePhone(){
            if(!this.code){
                this.$message({
                    message: '请输入验证码',
                    type: 'warning'
                });
                return;
            }
            this.$http.get("/api/sms/check",{
                params:{
                    mobile:this.phone,
                    event:'changemobile',
                    captcha:this.code
                }
            }).then((res)=>{
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            })
        },
        // 确定修改密码
        defineUpdatePassword(){
            if(this.passwordOne===""||this.passwordTwo===""){
                this.$message({
                    message: '请输入密码',
                    type: 'warning'
                });
                return;
            }
            if(this.passwordOne!==this.passwordTwo){
                this.$message({
                    message: '你输入的两次密码不一致',
                    type: 'warning'
                });
                return;
            }
            this.$http.get("/api/user/updatepwd",{
                params:{
                    newpassword:this.passwordTwo
                }
            }).then((res)=>{
                if (res.code != 1 && res.msg) {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                    return
                }
                this.$router.back();
            })
        },
        // 取消修改密码
        cancelUpdatePassword(){
            this.passwordOne="";
            this.passwordTwo=""
        }
    }
}
</script>

<style>

</style>