<template>
    <!-- 关于我们组件 -->
    <div id="aboutUs">
        <div class="mid">
            <img src="../img/logo.png" alt="">
             <p>{{version}}</p>
        </div>
        <div class="content">
            <div class="name">
                <div class="name-left">Email</div>
                <div class="name-right">
                    <input type="text" class="test" placeholder="zaihuaart@126.com" v-model="data.email">
                </div>
            </div>
            <div class="name">
                <div class="name-left">商务合作电话</div>
                <div class="name-right">
                    <input type="text" class="test" placeholder="18513000543" v-model="data.phone">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            data:{},
            updateInfo:{},
            version:'1.0.0',
        }
    },
    mounted(){
        this.refreshData();
        this.refreshUpdateInfoData();
    },
    methods:{
        refreshData(){
            this.$http.get("/api/setting/get_config_info",{
            }).then((res)=>{
                this.data=res.data
            })
        },
        refreshUpdateInfoData(){
            this.$http.get("/api/setting/get_update_info",{
            }).then((res)=>{
                this.updateInfo=res.data;
                if(this.updateInfo && this.updateInfo[0] && this.updateInfo[0].version){
                    this.version = this.updateInfo[0].version;
                }
            })
        },
    }
}
</script>

<style>

</style>