<template>
    <!-- 搜索用户 -->
    <div id="searchUsers">
        <div class="section" v-loading="loading">
            <!-- 用户组件 -->
            <users :userList="usersData"></users>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:false,
            usersData:[]
        }
    },
    watch:{
        "$store.state.keyWords"() {
            this.getUsersData()
        },
    },
    mounted(){
        this.getUsersData()
    },
    methods:{
        // 获取用户数据
        getUsersData(){
            this.loading=true;
            this.$http.get("/api/painting/common_search",{
                params:{
                    keywords:this.$store.state.keyWords
                }
            }).then((res)=>{
                this.loading=false;
                this.usersData = res && res.data && res.data.user || [];
            })
        }
    }
}
</script>

<style>

</style>