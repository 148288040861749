<template>
    <!-- 图集-免费图集 -->
    <!-- 注意：1.图不完整， 不规范
               2.收藏小爱心灰色图没有
               3.更多里的全部分类、投诉举报...图不全 -->
    <div id="freeAtlas">
        <div class="section">
            <div class="section-title">
                <div class="pcClass">
                    <div class="left">
                        <div class="title-info">
                            男性头像（20）
                        </div>
                    </div>
                    <div class="title-operation">
                        <div class="title-operation-module module-look">
                            <div class="img"></div>
                            <span>256 查看</span>
                        </div>
                        <div class="title-operation-module module-collection">
                            <div class="img"></div>
                            <span>15 收藏</span>
                        </div>
                        <div class="title-operation-module module-share">
                            <div class="img"></div>
                            <span>5 分享</span>
                            <div class="module-share-channel">
                                <div class="share-channel-list" 
                                    v-for="(item,index) in shareChannel" :key="index"
                                    :class="item.className">
                                    <div class="channel-list-img"></div>
                                    <div class="channel-list-type">{{item.type}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="title-operation-module module-more">
                            <div class="img" @click="xiatiaode"></div>
                            <div class="module-more-operation">
                                <div class="operation-list" 
                                    v-for="(item,index) in moreOperation" :key="index"
                                    :class="item.className"
                                    @click="moreOperationClick(item.text)">
                                    <div class="operation-img"></div>
                                    {{item.text}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 图集组件 -->
        <myAtlas></myAtlas>
        <!-- 图集举报组件 -->
        <report :show="reportShow" 
            @onCancel="onCancel" 
            @onConfirm="onConfirm">
        </report>
        <!-- 删除图集组件 -->
        <deleteAtlas :show="deleteAtlasShow" 
            @onCancelDelete="onCancelDelete" 
            @onConfirmDelete="onConfirmDelete">
        </deleteAtlas>
        <!-- 提示组件 -->
        <myBlackTip :show="myBlackTipShow"></myBlackTip>
    </div>
</template>

<script>
export default {
    data(){
        return{
            shareChannel:[
                {
                    className:"qq",
                    type:"QQ"
                },
                {
                    className:"qqZone",
                    type:"QQ空间"
                },
                {
                    className:"microBlog",
                    type:"微博"
                }
            ],
            moreOperation:[
                {
                    className:"allCategories",
                    text:"全部分类"
                },
                {
                    className:"complaintsReport",
                    text:"投诉举报"
                },
                {
                    className:"deleteAtlas",
                    text:"删除图集"
                }
            ],
            reportShow:false,
            deleteAtlasShow:false,
            myBlackTipShow:false,
        }
    },
    methods:{
        xiatiaode(){
            this.$router.push({path:"./unlockAtlas"})
        },
        moreOperationClick(text){
            console.log(text)
            if(text=="投诉举报"){
                this.reportShow=true
            }
            if(text=="删除图集"){
                this.deleteAtlasShow=true
            }
        },
        // 取消举报
        onCancel(){
            this.reportShow=false
            console.log("取消逻辑在这里")
        },
        // 确定举报
        onConfirm(){
            this.reportShow=false
            console.log("确定逻辑在这里")
        },
        // 取消删除
        onCancelDelete(){
            this.deleteAtlasShow=false
            console.log("取消删除逻辑在这里www")
        },
        // 确定删除
        onConfirmDelete(){
            this.deleteAtlasShow=false
            this.myBlackTipShow=true
            setTimeout(()=>{
                this.myBlackTipShow=false
            },1000)
            console.log("确定删除逻辑在这里www")
        }
    }
}
</script>

<style>

</style>