<template>
    <!-- 全部-综合(此乃路由) -->
    <div id="search">
        <div class="section">
            <div class="section-banner pcClass">
                <div class="section-banner-top">
                    <router-link v-for="(item,index) in dataList" :key="index" 
                        :to="item.to" tag="div" class="top-list" 
                        :class="listCurrent===index?'active':''">
                        <div class="top-list-text">{{item.text}}</div>
                    </router-link>
                </div>
            </div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            dataList:[
                {
                    to:"/search/",
                    text:"综合"
                },
                {
                    to:"/search/searchAtlas",
                    text:"图集"
                },
                {
                    to:"/search/searchUsers",
                    text:"用户"
                }
            ],
            listCurrent:0
        }
    },
    mounted(){
        
    },
    methods:{
        
    }
}
</script>

<style>

</style>