
<template>
	<!--<span class="zCircle" :style="`width:${circleSize}px;height:${circleSize}px;`">-->
	<span class="zCircle">
		<svg width="150" height="150" :viewBox="`0,0,${circleSize},${circleSize}`">
			<circle
                    :cx="size"
                    :cy="size"
                    :r="size"
                    :stroke="layerColor"
                    :stroke-width="`${strokeWidth}`"
                    :style="`opacity: 0.5;transform: translate(${circleTranslate}px, ${circleTranslate}px);stroke-dasharray: 10000;stroke-dashoffset:0;`"
            ></circle>
			<circle
                    v-if="circleLength > 0"
                    :cx="size"
                    :cy="size"
                    :r="size"
                    :stroke="fill"
                    :stroke-width="`${strokeWidth}`"
                    :style="`transition: all ease-out ${duration}s;stroke-dasharray: ${circleLength} 10000;stroke-dashoffset:0;transform: translate(${circleTranslate}px, ${circleTranslate}px) rotate(-90deg);transform-origin:${size}px ${size}px;`"
            ></circle>
		</svg>
	</span>
</template>

<script>
    export default {
        name: "zCircle",
        props: {
            //			轨道颜色
            layerColor: {
                type: String,
                default: "#eee"
            },
            //			填充颜色
            fill: {
                type: String,
                default: "#FA763B"
            },
            //			圆环直径，默认单位为 px
            size: {
                type: Number,
                default: 70
            },
            //			进度条宽度
            strokeWidth: {
                type: Number,
                default: 20
            },
            //			当前进度
            value: {
                type: Number,
                default: 0
            },
            //			动画速度 单位为秒
            duration: {
                type: Number,
                default: 1
            }
        },
        computed: {
            circleSize() {
                return(parseInt(this.size) + (parseInt(this.strokeWidth) / 2)) * 2;
            },
            circleTranslate() {
                return parseInt(this.strokeWidth) / 2;
            },
            circleLength(){
                let val = this.value;

                if(!val){ return 0; }
                val = parseInt(val);
                val = val < 0 ? 0 : val;
                val = val > 100 ? 100 : val;
                return (360 * val / 100) * Math.PI * this.size / 180;
            }
        }
    }
</script>

<style>

</style>