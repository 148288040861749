<template>
    <!-- 图片展示 -->
    <!-- 注意：左右操作的箭头图没有，我采用的是阿里矢量图，不太规范 -->
    <div id="pictureDisplay" :style="{'min-height':fullScreen?'0':''}">
        <div class="swiper" :class="fullScreen?'fullScreen':''">
            <div class="swiper-title" @click="backBtn">
                {{imageInfo.name}}({{imageList.length}})
                <div class="title-info-choice vip" v-if="imageInfo.channel === 'admin' && imageInfo.is_vip === 1">VIP</div>
                <div class="title-info-choice selected" v-if="imageInfo.channel === 'user' && imageInfo.status === 'accept' && imageInfo.is_jp">精选</div>
                <div class="title-info-choice free" v-if="imageInfo.channel === 'admin' && imageInfo.is_vip === 0">免费</div>
            </div>
            <div class="swiper-content">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div
                                class="swiper-slide"
                                v-for="(item,index) in imageList" :key="index"
                                :data-swiper-autoplay="timeSet"
                        >
                            <img v-show="!sj" class="swiper-content-img" :class="isTransform?'active':''" :src="$uploadUrl+item.url+$imageParams_big" fit="contain" @load="imageLoad" :data-index="index">
                            <div class="netBox" v-show="!sj && netShow">
                                <div class="net" :style="{
                                width:item.width/item.height>=proportion?'100%':item.width/item.height*swiperHeight+'px',
                                height:item.width/item.height>=proportion?item.height/item.width*swiperWidth+'px':'100%'
                                }"
                                ></div>
                            </div>
                            <img v-show="sj" class="swiper-content-img" :class="isTransform?'active':''" :src="$uploadUrl+item.url_sj+$imageParams_big" fit="contain" @load="imageLoad" :data-index="index">
                            <div class="netBox" v-show="sj && netShow">
                                <div class="net" :style="{
                                width:item.width_sj/item.height_sj>=proportion?'100%':item.width_sj/item.height_sj*swiperHeight+'px',
                                height:item.width_sj/item.height_sj>=proportion?item.height_sj/item.width_sj*swiperWidth+'px':'100%'
                                }"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-content-button greyLeft"></div>
                <div class="swiper-content-button greyRight"></div>
                <div class="swiper-content-num" v-if="sj">{{swiperIndex_sj+1}}/{{imageList.length}}</div>
                <div class="swiper-content-num" v-if="!sj">{{swiperIndex+1}}/{{imageList.length}}</div>
            </div>
            <!--<div class="swiper-content">-->
                <!--<div class="swiper-content-img"></div>-->
                <!--<div class="swiper-content-button greyLeft"></div>-->
                <!--<div class="swiper-content-button greyRight"></div>-->
                <!--<div class="swiper-content-num">5/15</div>-->
            <!--</div>-->
            <div class="swiper-operation">
                <div class="swiper-operation-progress">
                    <div :style="{width:percent+'%'}"></div>
                </div>
                <div class="swiper-operation-module">
                    <div :class="item.module==='yes'?'module-list active':'module-list'"
                        v-for="(item,index) in action" :key="index"
                        @click="moduleClick(index)">
                        <div :class="item.module==='yes'?item.className+' active':item.className">
                        </div>
                        <span class="module-list-text">{{item.text}}</span>
                        <!-- 定时的弹窗 -->
                        <div class="timing-module" @click.stop v-if="item.module=='yes'&&item.className=='timing'" >
                            <div class="timing-module-minute">
                                <input type="text" v-model="m" @keydown.enter="timeInputEnter()">
                                <div class="text">分钟</div>
                            </div>
                            <div class="timing-module-second">
                                <input type="text" v-model="s" @keydown.enter="timeInputEnter()">
                                <div class="text">秒</div>
                            </div>
                        </div>
                    </div>
                    <div class="fullScreen" 
                        @click="fullScreenClick"
                        :class="fullScreen?'active':''">
                        <div class="fullScreen-img"></div>
                        <span class="fullScreen-text" v-if="!fullScreen">全屏</span>
                        <span class="fullScreen-text" v-if="fullScreen">退出全屏</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section" v-if="fullScreen===false">
            <div class="section-tip pcClass">
                <div>图集</div>
                <div class="section-tip-select" @click="allBtn">
                    查看全部 
                    <div class="section-tip-img"></div>
                </div>
            </div>
            <!-- 图集组件 -->
            <myAtlas page="index" :myAtlasList="myAtlasList"></myAtlas>
            <!-- 底部组件 -->
            <myFooter></myFooter>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';                                  //轮播图组件
import 'swiper/dist/css/swiper.min.css';                      //轮播图样式
export default {
    data(){
        return{
            id:'',//图集id
            imageInfo:{},//图集信息
            imageList:[],

            swiperObj:null,
            swiperWidth:0,
            swiperHeight:0,
            proportion:0,
            activeIndex:0,
            swiperIndex:0,
            swiperIndex_sj:0,
            endTime:0,
            percent:0,
            percentTimeout:null,

            m:'00',
            s:'00',

            timeSet:1000*5,//默认自动切换时间
            netShow:false, //辅助线
            isPlay:false, //播放按钮
            sj:false, //随机
            isTransform:false, //反转

            fullScreen:false,   //全屏播放
            action:[
                {
                    className:"timing",
                    text:"定时",
                    module:'no',
                },
                {
                    className:"guide",
                    text:"辅助线",
                    module:'no'
                },
                {
                    className:"play",
                    module:'no',
                },
                {
                    className:"random",
                    text:"随机"
                },
                {
                    className:"flip",
                    text:"翻转"
                },
            ],

            myAtlasList:[],
        }
    },
    mounted(){
        this.id = this.$route.params.id;
        let index = this.$route.params.index*1;
        this.swiperIndex = index;
        this.refreshImageInfoData(()=>{
            this.swiperObj = new Swiper('.swiper-container', {
                autoplay:1000*5,
                autoplayDisableOnInteraction:false,
                speed:300,
                loop:true,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                nextButton:'.greyRight',
                prevButton:'.greyLeft',
                initialSlide:index,
                onAfterResize:(e)=>{
                    this.swiperWidth = e.width;
                    this.swiperHeight = e.height;
                    this.proportion = e.width/e.height;
                },
                onSlideChangeEnd: (swiper)=>{
                    this.activeIndex = swiper.activeIndex;
                    if(this.activeIndex >= this.imageList.length){
                        this.activeIndex = this.activeIndex % this.imageList.length;
                    }
                    this.swiperIndex = this.imageList[this.activeIndex].index;
                    this.swiperIndex_sj = this.imageList[this.activeIndex].index_sj;
                    this.endTime = new Date().getTime();
                    if(this.isPlay){
                        this.refreshPercentFun();
                    }
                }
            });
            this.swiperObj.stopAutoplay();
            this.swiperObj.slideTo(index,0,true);
        });
        this.refreshRecommendListData();
        document.getElementById("app").scrollIntoView();
    },
    methods:{
        imageLoad(e){
            let dom = {};
            if(e.path){
                dom = e.path[0];
            }
            if(e.target){
                dom = e.target;
            }
            let index = dom.dataset.index;
            let obj = this.imageList[index];
            obj.width = dom.naturalWidth;
            obj.height = dom.naturalHeight;
            obj.width_sj = dom.naturalWidth;
            obj.height_sj = dom.naturalHeight;
            this.$set(this.imageList,index,obj);
        },
        //功能按钮
        moduleClick(index){
            if(this.action[index].module==='yes'){
                this.action[index].module='no'
            }else{
                this.action[index].module='yes'
            }
            switch (this.action[index].className){
                case 'timing':
                    if(this.action[index].module === 'yes'){
                        let m = Math.floor(this.timeSet/1000/60);
                        this.m = m<10?'0'+m:m;
                        let s = Math.floor(this.timeSet/1000%60);
                        this.s = s<10?'0'+s:s;
                    }
                    break;
                case 'guide': //辅助线
                    if(this.action[index].module === 'yes'){
                        this.netShow = true;
                    }else{
                        this.netShow = false;
                    }
                    break;
                case 'play':
                    if(this.action[index].module === 'yes'){
                        this.isPlay = true;
                        // this.swiperObj.startAutoplay();
                        this.endTime = new Date().getTime();
                        this.refreshPercentFun();
                    }else{
                        this.isPlay = false;
                        // this.swiperObj.stopAutoplay();
                        if(this.percentTimeout){
                            clearTimeout(this.percentTimeout);
                        }
                        this.percent = 0;
                    }
                    break;
                case 'random': //随机
                    if(this.action[index].module === 'yes'){
                        let arr = JSON.parse(JSON.stringify(this.imageList));
                        arr.sort(function(){ return 0.5 - Math.random() });
                        for(let i = 0;i<arr.length;i++){
                            let obj = this.imageList[i];
                            obj.url_sj = arr[i].url;
                            obj.index_sj = arr[i].index;
                            obj.width_sj = arr[i].width;
                            obj.height_sj = arr[i].height;
                            this.$set(this.imageList,i,obj);
                        }
                        this.sj = true;
                        this.swiperIndex_sj = this.imageList[this.activeIndex].index_sj;
                    }else{
                        this.sj = false;
                    }
                    break;
                case 'flip': //反转
                    if(this.action[index].module === 'yes'){
                        this.isTransform = true;
                    }else{
                        this.isTransform = false;
                    }
                    break;
            }
        },
        //修改时间
        timeInputEnter(){
            this.timeSet = Math.round(this.m*60+this.s*1)*1000;
            this.$set(this.action,0,{
                className:"timing",
                text:"定时",
                module:'no',
            });
            this.$set(this.action,2, {
                    className:"play",
                    module:'yes',
                });

            this.isPlay = true;
            this.endTime = new Date().getTime();
            this.refreshPercentFun();
        },
        // 全屏按钮
        fullScreenClick(){
            this.fullScreen=!this.fullScreen
        },
        //全部按钮
        allBtn(){
            this.$router.push({ path: `/recommendedAtlas`});
        },

        // 刷新图集数据
        refreshImageInfoData(fun){
            this.$http.get("/api/painting/get_painting_id",{
                params:{
                    id:this.id
                }
            }).then((res)=>{
                this.imageInfo=res.data;
                let imageArr = res.data.pic.split(',');
                let arr = [];
                for(let i = 0;i<imageArr.length;i++){
                    arr.push({
                        url:imageArr[i],
                        index:i,
                        width:0,
                        height:0,
                        url_sj:imageArr[i],
                        index_sj:i,
                        width_sj:0,
                        height_sj:0,
                    });
                }
                this.imageList = arr;
                setTimeout(()=>{
                    if(fun){fun();}
                },1)
            })
        },

        //刷新推荐图集
        refreshRecommendListData(){
            this.$http.get("/api/painting/get_recommend_painting",{
                params:{
                    count:24
                }
            }).then((res)=>{
                this.myAtlasList=res.data;
            });
        },

        //进度计算
        refreshPercentFun(){
            if(this.percentTimeout){
                clearTimeout(this.percentTimeout);
            }
            this.percentTimeout = setTimeout(()=>{
                let nowTime = new Date().getTime();
                let time = nowTime - this.endTime;
                this.percent = time/this.timeSet*100;
                if(this.percent >= 100){
                    this.swiperObj.slideNext();
                }
                this.refreshPercentFun();
            },1000*0.1);
        },

        //返回按钮
        backBtn(){
            this.$router.back();
        }
    }
}
</script>

<style>

</style>