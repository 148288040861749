import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import index from "../views/index.vue";
import allCategories from "../views/allCategories.vue";
import search from "../views/search.vue";
import searchSynthesis from "../views/searchSynthesis.vue";
import searchAtlas from "../views/searchAtlas.vue";
import searchUsers from "../views/searchUsers.vue";
import freeAtlas from "../views/freeAtlas.vue";
import unlockAtlas from "../views/unlockAtlas.vue";
import editingMaterials from "../views/editingMaterials.vue";
import fillInInformation from "../views/fillInInformation.vue";
import settings from "../views/settings.vue";
import concern from "../views/concern.vue";
import aboutUs from "../views/aboutUs.vue";
import serviceUsageAgreement from "../views/serviceUsageAgreement.vue";
import privacyAgreement from "../views/privacyAgreement.vue";
import updatelog from "../views/updatelog.vue";
import feedback from "../views/feedback.vue";
import order from "../views/order.vue";
import recommendedAtlas from "../views/recommendedAtlas.vue";
import pictureDisplay from "../views/pictureDisplay.vue";
import userPerspective from "../views/userPerspective.vue";
import userPerspectiveOther from "../views/userPerspectiveOther.vue";
import editorialPresentation from "../views/editorialPresentation.vue";
import modifySettings from "../views/modifySettings.vue";
import myConcerns from "../views/myConcerns.vue";
import newAtlas from "../views/newAtlas.vue";
import bannerInfo from "../views/bannerInfo.vue";
import qqLogin from "../views/qqLogin.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",    //推荐图集
    name: "index",
    component: index
  },
  {
    path: "/allCategories/:index/:pid/:id",   //图集类型
    name: "allCategories",
    component: allCategories
  },
  {
    path: "/freeAtlas",   //免费图集
    name: "freeAtlas",
    component: freeAtlas
  },
  {
    path: "/unlockAtlas/:id",   //解锁图集
    name: "unlockAtlas",
    component: unlockAtlas
  },
  {
    path: "/search",    //搜索-综合
    name: "search",
    component: search,
    children:[//嵌套路由
      {
        path:"/",
        name:"searchSynthesis",
        component:searchSynthesis
      },
      {
        path:"searchAtlas",
        name:"searchAtlas",
        component:searchAtlas
      },
      {
        path:"searchUsers",
        name:"searchUsers",
        component:searchUsers
      }
    ]
  },
  {
      path:"/recommendedAtlas",    //图集-推荐图集-查看全部
      name:"recommendedAtlas",
      component:recommendedAtlas
  },
  {
    path:"/pictureDisplay/:id/:index",    //图片展示
    name:"pictureDisplay",
    component:pictureDisplay
  },
  {
    
    path: "/editingMaterials",    //我的-编辑资料
    name: "editingMaterials",
    component: editingMaterials
  },
  {
    path: "/editorialPresentation",    //我的-编辑资料-编辑资料展示
    name: "editorialPresentation",
    component: editorialPresentation
  },
  {
    path: "/modifySettings/:update",    //我的-编辑资料-编辑设置
    name: "modifySettings",
    component: modifySettings
  },
  {
    path: "/fillInInformation",   //我的-编辑资料-填写资料
    name: "fillInInformation",
    component: fillInInformation
  },
  {
    path: "/settings",    //我的-设置
    name: "settings",
    component: settings
  },
  {
    path: "/concern",   //关于我们
    name: "concern",
    component: concern,
    children:[    //嵌套路you
      {
        path:"/",   //关于我们-关于我们
        name:"aboutUs",
        component:aboutUs
      },
      {
        path: "serviceUsageAgreement",   //关于我们-服务使用协议
        name: "serviceUsageAgreement",
        component: serviceUsageAgreement
      },
      {
        path: "privacyAgreement",   //关于我们-隐私协议
        name: "privacyAgreement",
        component: privacyAgreement
      },
      {
        path: "updatelog",    //关于我们-更新日志
        name: "updatelog",
        component: updatelog
      },
      {
        path: "feedback",   //关于我们意见反馈
        name: "feedback",
        component: feedback
      }
    ]
  },
  {
    path: "/order/:index",   //我的订单
    name: "order",
    component: order
  },
  {
    path: "/userPerspective",   //我的-一期-用户视角
    name: "userPerspective",
    component: userPerspective
  },
  {
    path: "/userPerspectiveOther/:id",   //用户详情
    name: "userPerspectiveOther",
    component: userPerspectiveOther
  },
  {
    path: "/myConcerns/:index/:id",   //我的关注
    name: "myConcerns",
    component: myConcerns
  },
  {
    path: "/newAtlas",   //新建图集
    name: "newAtlas",
    component: newAtlas
  },
  {
    path: "/bannerInfo/:id",   //轮播详情
    name: "bannerInfo",
    component: bannerInfo
  },
  {
    path: "/qqLogin",
    name: "qqLogin",
    component: qqLogin
  }
];

const router = new VueRouter({
    mode:'history',
  routes
});

router.beforeEach((to,form,next) => {
    let path = to.path;
    //白名单
    let whiteList = [
        'index', //首页
        'allCategories', //分类
        'search', //搜索
        'unlockAtlas', //图集简介
        'concern', //关于我们，服务使用协议，隐私协议，更新日志，意见反馈
        'bannerInfo', //轮播详情
        'qqLogin', //轮播详情
    ];
    var isPath = false;
    for(var i = 0 ;i<whiteList.length;i++){
      if(path.split('/').indexOf(whiteList[i]) >= 0){
        isPath = true;
      }
    }
    if(path !== '/'){
        if(isPath){
            next();
        }else{
            if(localStorage.getItem("token")){
                next();
            }else{
                store.commit('setIsLogin',true);
            }
        }
    }else{
        next();
    }
});

export default router;

// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err)
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err)
};