<template>
    <!-- 各种单按钮（不含input的）提示框组件（含解锁图集-支付弹窗，充值服务协议，查看规则） -->
    <div class="mask myTip" v-show="show">
        <div class="mask-section">
            <div class="title" v-show="title!=='规则说明'">{{title}}</div>
            <div class="title" v-show="title==='规则说明'" style="padding-bottom: 0;">{{title}}</div>
            <div class="close" @click="closeClick"></div>
            <!-- 解锁图集-支付弹窗 -->
            <div class="content" v-show="title=='解锁图集'">
                <div class="content-tip">
                    {{text}}
                </div>
            </div>
            <!-- 充值服务协议 -->
             <div class="content" v-show="title=='充值服务协议'" style="color: #000000;">
                <p>1.金瓜子可以用于APP内解锁图集（不包含解锁VIP图集）；</p>
                <p>2.iOS系统、安卓系统、在画网站的金瓜子余额可以互相通用；</p>
                <p> 3.金瓜子为虚拟币，充值后的金瓜子不会过期，但无法提现或转增他人。</p>
            </div>
            <!-- 规则说明 -->
            <div class="content rule" v-show="title=='规则说明'" v-html="text" style="color:#666666;white-space: pre-wrap;">
                <!--<p>1.请严格遵守法律法规，严禁上传涉黄、涉政、暴恐、违禁、广告等违法图片</p>-->
                <!--<p>2.图集发布后有<span>24小时</span>审核期，审核通过前仅自己可见，审核通过后所有人可见；</p>-->
                <!--<p>3.精选图集会被收录在图集全部分类中，增加曝光量；</p>-->
                <!--<p> 4.其他用户解锁图集，图集发布者将获得<span>1个金瓜子奖励</span></p>-->
            </div>
            <div class="button" @click="buttonClick" v-show="title!=='规则说明'">
                {{(title === '规则说明')?'确定':(title === '充值服务协议'?'同意':'关注并支付')}}
            </div>
            <div class="button rule" @click="buttonClick" v-show="title=='规则说明'">确定</div>
        </div>
    </div>
</template>

<script>
export default {
    name:"myTip",
    props:{
        show:{
            type:Boolean,
            default:false
        },
        title:{
            type:String,
            default:""
        },
        text:{
            type:String,
            default:""
        }
    },
    data(){
        return{

        }
    },
    methods:{
        closeClick(){
            this.$emit('onCloseClick');
        },
        buttonClick(){
            this.$emit('onButtonClick');
        }
    }
}
</script>

<style>

</style>