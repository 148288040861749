<template>
    <div class="mask authentication">
        <div class="mask-section">
            <div class="title" v-if="title!=='身份验证'">{{title}}</div>
            <div class="title authentication" v-if="title==='身份验证'">{{title}}</div>
            <div class="close" @click="closeClick"></div>
            <!-- 身份验证 -->
            <div class="authentication" v-if="title=='身份验证'">
                <div class="content">
                    <div class="content-tip">
                        为了保护你的账号安全，请验证身份， 验证成功后进行下一步操作
                    </div>
                    <div class="content-operation">
                        <div class="content-operation-phone">
                            使用手机 +86{{mobileFun(userinfo.mobile)}} 验证
                        </div>
                        <div class="content-operation-code">
                            <input type="text" placeholder="输入4位短信验证码" v-model="code">
                            <div class="getCode" @click="getCode" v-if="!loginTime">获取短信验证码</div>
                            <div class="getCode active" v-if="loginTime">{{loginTime}}s后重新发送</div>
                        </div>
                    </div>
                </div>
                <div class="button" @click="buttonClick">
                    验证
                </div>
            </div>
            <!-- 修改密码 -->
            <div class="changePassword" v-if="title=='修改密码'">
                <div class="content">
                    <div class="content-tip">
                        设置新密码
                    </div>
                    <div class="content-operation">
                        <div class="content-operation-input">
                            <div class="title">设置密码</div>
                            <input type="text" placeholder="请输入新密码" v-model="pass1">
                        </div>
                        <div class="content-operation-input">
                            <div class="title">确认密码</div>
                            <input type="text" placeholder="请再次输入新密码" v-model="pass2">
                        </div>
                    </div>
                </div>
                <div class="button" @click="buttonClick">
                    完成修改
                </div>
            </div>
            <!-- 找回密码 -->
            <div class="retrievePassword" v-if="title=='找回密码'">
                <div class="content">
                    <div class="content-operation">
                        <div class="content-operation-phone">
                            <div class="area">
                                <div class="area-text">
                                    {{areaNum[current].num}}
                                    <div class="bottom_arrows"></div>
                                </div>
                                <!--<div class="area-text" @click="areaSelectClick">-->
                                    <!--{{areaNum[current].num}}-->
                                    <!--<div class="bottom_arrows"></div>-->
                                <!--</div>-->
                                <!--<div class="area-module" v-show="areaSelect">-->
                                    <!--<div class="list" v-for="(item,index) in areaNum" :key="index"-->
                                        <!--:class="current==index?'active':''"-->
                                        <!--@click="areaNumClick(index)">-->
                                        <!--{{item.num}}-->
                                    <!--</div>-->
                                <!--</div>-->
                            </div>
                            <input type="text" placeholder="请输入手机号码" v-model="findMobile">
                        </div>
                        <div class="content-operation-code">
                            <input type="text" placeholder="输入4位短信验证码" v-model="code">
                            <div class="getCode" @click="getCode_find" v-if="!loginTime">获取短信验证码</div>
                            <div class="getCode active" v-if="loginTime">{{loginTime}}s后重新发送</div>
                        </div>
                    </div>
                </div>
                <div class="button" @click="buttonClick">
                    验证
                </div>
            </div>
            <!-- 找回密码 -->
            <div class="retrievePassword" v-if="title=='绑定手机号'">
                <div class="content">
                    <div class="content-operation">
                        <div class="content-operation-phone">
                            <div class="area">
                                <div class="area-text">
                                    {{areaNum[current].num}}
                                    <div class="bottom_arrows"></div>
                                </div>
                                <!--<div class="area-text" @click="areaSelectClick">-->
                                    <!--{{areaNum[current].num}}-->
                                    <!--<div class="bottom_arrows"></div>-->
                                <!--</div>-->
                                <!--<div class="area-module" v-show="areaSelect">-->
                                    <!--<div class="list" v-for="(item,index) in areaNum" :key="index"-->
                                        <!--:class="current==index?'active':''"-->
                                        <!--@click="areaNumClick(index)">-->
                                        <!--{{item.num}}-->
                                    <!--</div>-->
                                <!--</div>-->
                            </div>
                            <input type="text" placeholder="请输入手机号码" v-model="findMobile">
                        </div>
                        <div class="content-operation-code">
                            <input type="text" placeholder="输入4位短信验证码" v-model="code">
                            <div class="getCode" @click="getCode_mobileLogin" v-if="!loginTime">获取短信验证码</div>
                            <div class="getCode active" v-if="loginTime">{{loginTime}}s后重新发送</div>
                        </div>
                    </div>
                </div>


                <div class="button" @click="buttonClick">
                    验证
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"authentication",
    props:{
        title:{
            type:String,
            default:"找回密码"
        }
    },
    data(){
        return{
            areaNum:[
                {num:"+86"},
                {num:"+61"},
                {num:"+85"}
            ],
            areaSelect:false,
            current:0,
            userinfo:{},
            code:'',
            loginTime:0,
            findMobile:'',
            pass1:'',
            pass2:'',
        }
    },
    mounted(){
        this.userinfo=JSON.parse(localStorage.getItem("userinfo") || '{}')
    },
    methods:{
        closeClick(){
            // this.show=false
            this.$emit('onCloseClick');
        },
        buttonClick(){
            this.$emit('onButtonClick',this.userinfo,this.code,this.findMobile,this.pass1,this.pass2);
        },
        areaSelectClick(){
            this.areaSelect=true
        },
        areaNumClick(index){
            this.current=index;
            this.areaSelect=false
        },
        // 获取验证码
        getCode(){
            this.$http.get("/api/sms/send",{
                params:{
                    code:"86",
                    mobile:this.userinfo.mobile,
                    event:'changemobile1'
                }
            }).then((res)=>{
                if(res.code === 0){
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }else{
                    this.loginTime = 59;
                    this.timeFun();
                }
            })
        },
        // 获取验证码
        getCode_find(){
            this.$http.get("/api/sms/send",{
                params:{
                    code:"86",
                    mobile:this.findMobile,
                    event:'resetpwd'
                }
            }).then((res)=>{
                if(res.code === 0){
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }else{
                    this.loginTime = 59;
                    this.timeFun();
                }
            })
        },
        // 获取验证码
        getCode_mobileLogin(){
            this.$http.get("/api/sms/send",{
                params:{
                    code:"86",
                    mobile:this.findMobile,
                    event:'mobilelogin'
                }
            }).then((res)=>{
                if(res.code === 0){
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }else{
                    this.loginTime = 59;
                    this.timeFun();
                }
            })
        },

        timeFun:function(){
            let _this = this;
            let goFun = function(){
                let time = _this.loginTime--;
                if(time>0){
                    setTimeout(function(){
                        goFun();
                    },1000);
                }else{
                    _this.loginTime = 0;
                }
            };
            goFun();
        },
        mobileFun(input){
            if (input !== undefined) {
                input =
                    input.substring(0, 3) + " **** " + input.substring(input.length - 4);
            }
            return input;
        }
    }
}
</script>

<style>

</style>