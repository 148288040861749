<template>
    <!-- 提示框-（对应蓝湖：确认删除） -->
    <div class="mask myBlackTip">
        <div class="mask-section">
            <img src="../img/queding.png" alt="">
            <div class="mask-section-text">{{text}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name:"myTip",
    props:{
        text:{
            type:String,
            default:'图集已删除'
        }
    },
    data(){
        return{
            
        }
    },
}
</script>

<style>

</style>